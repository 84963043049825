import React, { useEffect, useState } from "react";
import Button from "../../extra/Button";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Input from "../../extra/Input";
import { connect, useDispatch, useSelector } from "react-redux";
import { createBrand, updateBrand } from "../../store/brand/brand.action";

const BrandDialog = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();

  const [mongoId, setMongoId] = useState(0);
  const [brandName, setBrandName] = useState("");
  const [error, setError] = useState({
    brandName: "",
  });

  useEffect(() => {
    setMongoId(dialogueData?._id || 0);
    setBrandName(dialogueData?.brandName || "");
  }, [dialogueData]);

  const handleSubmit = async () => {
    let formHasError = false;
    let errorObj = { ...error };

    if (!brandName) {
      errorObj.brandName = "Brand Name is Required!";
      formHasError = true;
    }

    if (formHasError) {
      return setError({ ...errorObj });
    }

    try {
      const formData = {
        brandName: brandName,
      };

      if (mongoId) {
        await dispatch(updateBrand(formData, mongoId));
      } else {
        await dispatch(createBrand(formData));
      }
      dispatch({ type: CLOSE_DIALOGUE });
    } catch (error) {
      console.error("İşlem sırasında hata:", error);
    }
  };

  
return (
    <div className="mainDialogue fade-in">
      <div className="Dialogue">
        <div className="dialogueHeader">
          <div className="headerTitle fw-bold">Brand</div>
          <div
            className="closeBtn"
            onClick={() => {
              dispatch({ type: CLOSE_DIALOGUE });
            }}
          >
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
        <div className="dialogueMain">
          <div className="row">
            <div className="col-12">
              <Input
                label="Brand Name"
                id="brandName"
                newClass="text-capitalize"
                type="text"
                value={brandName}
                errorMessage={error.brandName && error.brandName}
                onChange={(e) => {
                  setBrandName(e.target.value);
                  setError((prevErrors) => ({ ...prevErrors, brandName: "" }));
                }}
              />
            </div>
          </div>
        </div>
        <div className="dialogueFooter">
          <div className="dialogueBtn">
            {!mongoId ? (
              <Button
                btnName="Submit"
                btnColor="btnBlackPrime text-white"
                style={{ borderRadius: "5px", width: "80px" }}
                newClass="me-2"
                onClick={handleSubmit}
              />
            ) : (
              <Button
                btnName="Update"
                btnColor="btnBlackPrime text-white"
                style={{ borderRadius: "5px", width: "80px" }}
                newClass="me-2"
                onClick={handleSubmit}
              />
            )}
            <Button
              btnName="Close"
              btnColor="bg-danger text-white"
              style={{ borderRadius: "5px", width: "80px" }}
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandDialog;