import * as actionType from "./influencer.type";

//Initial State
const initialState = {
  influencers: [],
};

export const influencerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_INFLUENCER_LIST:
      return {
        ...state,
        influencers: action.payload,
      };
    default:
      return state;
  }
};

