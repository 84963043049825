import { apiInstanceFetch } from '../../util/api';
import {
  GET_CREDIT_INVOICES,
  GET_CREDIT_INVOICES_SUCCESS,
  GET_CREDIT_INVOICES_FAIL,
  CREATE_CREDIT_INVOICE,
  CREATE_CREDIT_INVOICE_SUCCESS,
  CREATE_CREDIT_INVOICE_FAIL,
  UPDATE_CREDIT_INVOICE,
  UPDATE_CREDIT_INVOICE_SUCCESS,
  UPDATE_CREDIT_INVOICE_FAIL,
  DELETE_CREDIT_INVOICE,
  DELETE_CREDIT_INVOICE_SUCCESS,
  DELETE_CREDIT_INVOICE_FAIL
} from './creditInvoice.type';

export const getCreditInvoices = () => async (dispatch) => {
  try {
    dispatch({ type: GET_CREDIT_INVOICES });
    const response = await apiInstanceFetch.get('subscription/getCreditInvoices');
    dispatch({
      type: GET_CREDIT_INVOICES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_CREDIT_INVOICES_FAIL,
      payload: error.message,
    });
  }
};

export const createCreditInvoice = (data) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_CREDIT_INVOICE });
    const response = await apiInstanceFetch.post('credit/createInvoice', data);
    dispatch({
      type: CREATE_CREDIT_INVOICE_SUCCESS,
      payload: response.data,
    });
    dispatch(getCreditInvoices());
  } catch (error) {
    dispatch({
      type: CREATE_CREDIT_INVOICE_FAIL,
      payload: error.message,
    });
  }
};

export const updateCreditInvoice = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CREDIT_INVOICE });
    const response = await apiInstanceFetch.put(`credit/updateInvoice/${id}`, data);
    dispatch({
      type: UPDATE_CREDIT_INVOICE_SUCCESS,
      payload: response.data,
    });
    dispatch(getCreditInvoices());
  } catch (error) {
    dispatch({
      type: UPDATE_CREDIT_INVOICE_FAIL,
      payload: error.message,
    });
  }
};

export const deleteCreditInvoice = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CREDIT_INVOICE });
    await apiInstanceFetch.delete(`credit/deleteInvoice/${id}`);
    dispatch({
      type: DELETE_CREDIT_INVOICE_SUCCESS,
      payload: id,
    });
    dispatch(getCreditInvoices());
  } catch (error) {
    dispatch({
      type: DELETE_CREDIT_INVOICE_FAIL,
      payload: error.message,
    });
  }
};
