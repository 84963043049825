import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createTerm, updateTerm } from "../../store/termAndCondition/termAndCondition.action";
import Button from "../../extra/Button";
import Input from "../../extra/Input";

const TermAndConditionEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const termData = location.state;

  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (termData) {
      setFormData({
        title: termData.title || "",
        description: termData.description || "",
      });
    }
  }, [termData]);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.title.trim()) newErrors.title = "Title is required";
    if (!formData.description.trim()) newErrors.description = "Content is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setErrors(prev => ({
      ...prev,
      [name]: ""
    }));
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const submitData = {
        title: formData.title.trim(),
        description: formData.description.trim(),
      };

      if (termData?._id) {
        dispatch(updateTerm(termData._id, submitData));
      } else {
        dispatch(createTerm(submitData));
      }
      navigate(-1);
    }
  };

  return (
    <div className="mainSellerTable">
      <div className="sellerTable">
        <div className="sellerHeader primeHeader">
          <h5>{termData?._id ? "Term Edit" : "Add Term"}</h5>
        </div>
        <div className="sellerMain">
          <div className="p-4">
            <div className="row">
              <div className="col-12 mb-3">
                <Input
                  type="text"
                  label="Title"
                  name="title"
                  required={true}
                  value={formData.title}
                  onChange={handleChange}
                  error={errors.title}
                  style={{
                    backgroundColor: '#fbe9e7', 
                    borderRadius: '8px',
                    padding: '10px',
                    border: '1px solid #ffccbc'
                  }}
                />
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label className="form-label">
                    Content{' '}
                    {errors.description && (
                      <span className="text-danger">({errors.description})</span>
                    )}
                  </label>
                  <div className="custom-textarea-container" style={{ position: 'relative' }}>
                    <textarea
                      className="form-control"
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      rows={8}
                      style={{
                        borderRadius: '8px',
                        padding: '15px',
                        fontSize: '14px',
                        lineHeight: '1.5',
                        border: errors.description ? '1px solid #dc3545' : '1px solid #ced4da',
                        width: '100%',
                        resize: 'vertical', // Sadece dikey boyutlandırma
                        minHeight: '150px' // Minimum yükseklik
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <Button
                btnName="Cancel"
                btnColor="btn-secondary"
                onClick={() => navigate(-1)}
                className="me-2"
              />
              <Button
                btnName="Save"
                btnColor="btn-primary"
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermAndConditionEdit;