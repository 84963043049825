import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../extra/Table";
import Button from "../../extra/Button";
import Pagination from "../../extra/Pagination";
import { fetchSubscriptions } from "../../store/subscription/subscription.action";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Searching from "../../extra/Searching";
import SubscriptionModal from "./SubscriptionModal";

const Subscription = () => {
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const subscriptions = useSelector((state) => state.subscription.subscriptions);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await dispatch(fetchSubscriptions());
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (subscriptions && Array.isArray(subscriptions)) {
      setData(subscriptions);
    }
  }, [subscriptions]);

  const handleShowModal = (subscription) => {
    setSelectedSubscription(subscription);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedSubscription(null);
  };

  const mapData = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => <span>{index + 1}</span>,
    },
    
   
    {
      Header: "Is Influencer",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">{row?.isInfluecer ? "Yes" : "No"}</p>
        </div>
      ),
    },
    {
      Header: "Is Trial",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">{row?.isTrial ? "Yes" : "No"}</p>
        </div>
      ),
    },
    {
      Header: "Status",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">{row?.isActive ? "Active" : "Inactive"}</p>
        </div>
      ),
    },
    {
      Header: "Price",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">{row?.price || "0"} kr</p>
        </div>
      ),
    },
    {
      Header: "Invoice",
      Cell: ({ row }) => (
        <div>
          {row?.pdfUrl && (
            <a 
              href={row.pdfUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              <i className="fas fa-file-pdf"></i>
            </a>
          )}
        </div>
      ),
     },
    {
      Header: "Details",
      Cell: ({ row }) => (
        <Button
          newClass="themeFont boxCenter userBtn fs-5"
          btnIcon="fa-solid fa-info"
          style={{
            borderRadius: "5px",
            margin: "auto",
            width: "40px",
            backgroundColor: "#fff",
            color: "#160d98",
          }}
          onClick={() => handleShowModal(row)}
        />
      ),
    },
    {
      Header: "Created At",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">
            {row?.createdAt ? new Date(row.createdAt).toLocaleDateString('dk-DK') : '-'}
          </p>
        </div>
      ),
    },
    {
    Header: "Subscription Type",
    Cell: ({ row }) => (
      <div>
        <p className="mb-0">{row?.subscriptionType || "-"}</p>
      </div>
    ),
  },
  ];

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  if (loading) {
    return (
      <div className="p-4">
        <Skeleton count={5} height={40} className="mb-2" />
      </div>
    );
  }

  return (
    <div className="subscription-table-container">
      <div className="subscription-table">
        <div className="subscription-header">
          <div className="row">
            <div className="col-12">
              <Searching
                type="client"
                data={subscriptions}
                setData={setData}
                column={subscriptions}
                onFilterData={handleFilterData}
                serverSearching={handleFilterData}
                button={true}
                setSearchValue={setSearch}
                searchValue={search}
              />
            </div>
          </div>
        </div>

        <div className="subscription-content">
          <div className="table-wrapper mt-2">
            {!data || data.length === 0 ? (
              <div className="empty-state text-center p-4">
                Subscription not found
              </div>
            ) : (
              <>
                <Table
                  data={data}
                  mapData={mapData}
                  PerPage={rowsPerPage}
                  Page={page}
                  type="client"
                />
                <Pagination
                  component="div"
                  count={data.length}
                  serverPage={page}
                  type="client"
                  onPageChange={handleChangePage}
                  serverPerPage={rowsPerPage}
                  totalData={data.length}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
          </div>
        </div>
      </div>

      <SubscriptionModal
        show={showModal}
        onHide={handleCloseModal}
        subscription={selectedSubscription}
      />
    </div>
  );
};

export default Subscription;