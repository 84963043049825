import * as actionType from "./story.type";
import { apiInstanceFetch } from "../../util/api";
import axios from "axios";
import { setToast } from "../../util/toast";

export const fetchStoryList = () => (dispatch) => {
  console.log("Hikayeler getiriliyor..."); 
  
  return apiInstanceFetch.get(`story`).then((res) => {
    console.log("API yanıtı:", res);
    
    if (res && Array.isArray(res)) {
      dispatch({
        type: actionType.GET_STORY,
        payload: res 
      });
    } else {
      setToast("error", res?.message || "error while fetching stories.");
    }
  })
  .catch((error) => {
    console.error("Hikaye getirme hatası:", error);
    setToast("error", "error while fetching stories.");
  });
};


export const createStory = (story) => (dispatch) => {
  return axios.post(`story/createStory`, story)
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: actionType.CREATE_STORY,
          payload: res.data.story
        });
        setToast(res.data.message || "Story created successfully", "success");
        return res.data;
      } else {
        setToast(res.data.message || "Story creation failed", "error");
        throw new Error(res.data.message);
      }
    })
    .catch((error) => {
      console.error('Create error:', error.response?.data || error);
      setToast(
        error.response?.data?.message || 
        error.message || 
        "Error while creating story", 
        "error"
      );
      throw error;
    });
};

export const updateStory = ({ id, data }) => (dispatch) => {
  const requestData = {
    storyId: id,
    ...data
  };

  console.log('Request Data:', requestData); 

  return axios.post(`story/updateStory`, requestData)
    .then((res) => {
      console.log('Response:', res.data); 
      
      if (res.data.success) {
          setToast(res.data.message || "Story updated successfully", "success");
        dispatch({
          type: actionType.UPDATE_STORY,
          payload: res.data.story
        });
        return res.data;
      } else {
          setToast(res.data.message || "Update failed", "error");
        throw new Error(res.data.message);
      }
    })
    .catch((error) => {
      console.error('Update error:', error.response?.data || error);
      setToast(
        error.response?.data?.message || 
        error.message || 
        "Error while updating story", 
        "error"
      );
      throw error;
    });
};

export const deleteStory = (id) => (dispatch) => {
  return axios.delete(`story/deleteStory/${id}`)
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: actionType.DELETE_STORY,
          payload: id,
        });
          setToast(res.data.message || "Story deleted successfully", "success");
        
        return dispatch(fetchStoryList());
      } else {
        setToast(res.data.message || "Story deletion failed", "error");
        throw new Error(res.data.message);
      }
    })
    .catch((error) => {
      console.error('Delete error:', error.response?.data || error);
      setToast(
        error.response?.data?.message || 
        error.message || 
        "Error while deleting story", 
        "error"
      );
      throw error;
    });
};
