import "./App.css";
import Login from "./Component/Pages/Login.js";
import Admin from "./Component/Pages/Admin";
import PrivateRoute from "./Component/util/PrivateRoute";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { LOGIN_ADMIN, LOGOUT_ADMIN } from "./Component/store/admin/admin.type";
import Registration from "./Component/Pages/Registration.js";
import UpdateCode from "./Component/Pages/UpdateCode.js";
import axios from "axios";
import { useCallback } from "react";
function App() {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        const verifyAuth = async () => {
          try {
            const isAuth = localStorage.getItem("isAuth");
            
            if (!isAuth) {
              handleLogout();
              return;
            }
    
            const res = await axios.get("/login");
            
            if (res.data.status && res.data.login) {
              dispatch({ type: LOGIN_ADMIN });
              if (window.location.pathname === '/client/login') {
                navigate('/admin/dashboard');
              }
            } else {
              handleLogout();
            }
          } catch (err) {
            console.error('Auth verification failed:', err);
            handleLogout();
          } finally {
            setLoading(false);
          }
        };
    
        verifyAuth();
      }, [dispatch, navigate]);

      const handleLogout = useCallback(() => {
        localStorage.removeItem('isAuth');
        sessionStorage.removeItem('isAuth'); 
        dispatch({ type: LOGOUT_ADMIN });
        navigate('/client/login');
      }, [dispatch, navigate]);


    if (loading) {
        return (
            <div className="mainLoaderBox">
                <div className="loader"></div>
            </div>
        );
    }

    return (
        <div className="App">
            <Routes>
                <Route path="/" element={
                    localStorage.getItem("isAuth")
                        ? <Navigate to="/admin/dashboard" />
                        : <Navigate to="/client/login" />
                } />

                <Route path="/client/login" element={
                    localStorage.getItem("isAuth")
                        ? <Navigate to="/admin/dashboard" />
                        : <Login />
                } />
                <Route path="/registration" element={<Registration />} />
                <Route path="/code" element={<UpdateCode />} />

                <Route element={<PrivateRoute />}>
                    <Route path="/admin/*" element={<Admin />} />
                </Route>

            </Routes>
        </div>
    );
}

export default App;