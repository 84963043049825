import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../extra/Table";
import Button from "../../extra/Button";
import Pagination from "../../extra/Pagination";
import { getInfluencerPartnerships, deleteInfluencerPartnership } from "../../store/InfluencerPartnership/influencerPartnership.action";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import { warning } from "../../util/Alert";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import InfluencerPartnershipDialog from "./InfluencerPartnershipDialog";
import Searching from "../../extra/Searching";

const InfluencerPartnership = () => {
  const dispatch = useDispatch();
  
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]); 

  const partnerships = useSelector((state) => state.influencerPartnership?.partnerships || []);
  const { dialogue } = useSelector((state) => state.dialogue);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getInfluencerPartnerships());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (Array.isArray(partnerships)) {
      setData(partnerships);
    }
  }, [partnerships]);

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
      
      if (!filteredData.trim()) {
        setData(partnerships);
        return;
      }

      const searchTerm = filteredData.toLowerCase().trim();

      const filtered = partnerships.filter((item) => {
        const firstName = item.influencerId?.userId?.firstName?.toLowerCase() || '';
        const lastName = item.influencerId?.userId?.lastName?.toLowerCase() || '';
        const productName = item.productId?.productName?.toLowerCase() || '';
        const commissionRate = String(item.commissionRate || '');

        return (
          firstName.includes(searchTerm) ||
          lastName.includes(searchTerm) ||
          productName.includes(searchTerm) ||
          commissionRate.includes(searchTerm)
        );
      });

      setData(filtered);
    } else {
      setData(filteredData);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleDelete = async (id) => {
    const result = warning();
    result.then((isDeleted) => {
      if (isDeleted) {
        dispatch(deleteInfluencerPartnership(id));
      }
    }).catch((err) => console.log(err));
  };

  if (loading) {
    return (
      <div className="p-4">
        <Skeleton count={5} height={40} className="mb-2" />
      </div>
    );
  }
  const mapData = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => <span>{index + 1}</span>,
    },
    {
      Header: "Influencer Name",
      body: "influencerId.userId.firstName",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0 text-capitalize">
            {`${row.influencerId?.userId?.firstName || ""} ${row.influencerId?.userId?.lastName || ""}`}
          </p>
        </div>
      ),
    },
    {
      Header: "Product Name",
      body: "productId.productName",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">{row.productId?.productName || "-"}</p>
        </div>
      ),
    },
    {
      Header: "Commission Rate",
      body: "commissionRate",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">%{row.commissionRate || "0"}</p>
        </div>
      ),
    },
    {
      Header: "Social Media",
      body: "influencerId.socialMediaLinks",
      Cell: ({ row }) => {
        const socialLinks = row.influencerId?.socialMediaLinks || [];
        return (
          <div className="d-flex gap-2">
            {socialLinks.map((social, index) => {
              let baseUrl = '';
              let username = '';
              
              if (social.url) {
                switch(social.platform) {
                  case 'Instagram':
                    baseUrl = 'https://instagram.com/';
                    username = social.url.split('instagram.com/').pop();
                    break;
                  case 'Twitter':
                    baseUrl = 'https://twitter.com/';
                    username = social.url.split('twitter.com/').pop();
                    break;
                  case 'Youtube':
                    baseUrl = 'https://youtube.com/';
                    username = social.url.split('youtube.com/').pop();
                    break;
                  case 'TikTok':
                    baseUrl = 'https://tiktok.com/@';
                    username = social.url.split('tiktok.com/@').pop();
                    break;
                  default:
                    baseUrl = social.url;
                }
              }

              return (
                <a
                  key={index}
                  href={baseUrl + username}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-light border"
                >
                  <i className={`bi bi-${social.platform.toLowerCase()} me-2`}></i>
                  {social.platform}
                </a>
              );
            })}
            {socialLinks.length === 0 && <span>-</span>}
          </div>
        );
      },
    },
    {
        Header: "On Request",
        body: "onRequest",
        Cell: ({ row }) => (
            <div>
                <p className="mb-0">{row.onRequest ? "Yes" : "No"}</p>
            </div>
        )
    },
    {
      Header: "Status",
      body: "isActive",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">
            {row.isActive ? "Active" : "Inactive"}
          </p>
        </div>
      ),
    },
   
  ];
  return (
    <div className="mainSellerTable">
      <div className="sellerTable">
        <div className="sellerHeader primeHeader">
          <div className="row">
            <div className="col-4">
            
              {dialogue && <InfluencerPartnershipDialog />}
            </div>
            <div className="col-8">
              <Searching
                type="client"
                data={partnerships}
                setData={setData}
                column={mapData}
                button={true}
                setSearchValue={setSearch}
                searchValue={search}
                onFilterData={handleFilterData}
                placeholder="Name, product and commission search..."
              />
            </div>
          </div>
        </div>

        <div className="sellerMain">
          <div className="tableMain mt-2 brandTable">
            {!Array.isArray(data) || data.length === 0 ? (
              <div className="text-center p-4">
                <p>Influencer partnership not found</p>
              </div>
            ) : (
              <>
                <Table
                  data={data}
                  mapData={mapData}
                  PerPage={rowsPerPage}
                  Page={page}
                  type="client"
                />
                <div className="d-flex justify-content-end mt-3">
                  <Pagination
                    component="div"
                    count={data.length}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfluencerPartnership;