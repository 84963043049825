    import * as actionType from "./plan.type";
    
    const initialState = {
      plans: [],
    };
    
    export const planReducer = (state = initialState, action) => {
      switch (action.type) {
        case actionType.GET_PLAN_LIST:
          return {
            ...state,
            plans: action.payload,
          };
        case actionType.CREATE_PLAN:
          return {
            ...state,
            plans: [action.payload, ...state.plans],
          };
        case actionType.UPDATE_PLAN:
          return {
            ...state,
            plans: state.plans.map(plan => 
              plan._id === action.payload._id ? action.payload : plan
            )
          };
        case actionType.DELETE_PLAN:
          return {
            ...state,
            plans: state.plans.filter(plan => plan._id !== action.payload),
          };
        default:
          return state;
      }
    };
