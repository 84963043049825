import React from 'react';
import './ProductModal.css';

const ProductsModal = ({ show, onHide, products = [], storyData }) => {
  if (!show || !storyData) return null;
  
  const validProducts = storyData.selectedProducts || [];

  return (
    <>
      <div className="custom-modal-overlay"></div>
      <div className="custom-modal">
        <div className="custom-modal-dialog">
          <div className="custom-modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Story Detayları - {storyData?.businessName || '-'}</h5>
              <button type="button" className="btn-close" onClick={onHide}></button>
            </div>

            <div className="modal-body">
              {/* Story Bilgileri */}
              <div className="mb-4">
                <div className="d-flex align-items-center mb-3">
                  <i className="bi bi-info-circle me-2"></i>
                  <h6 className="m-0">Story Bilgileri</h6>
                </div>
                <div className="bg-light p-3 rounded">
                  <div className="row">
                    <div className="col-md-6">
                      <p className="mb-2"><strong>İşletme Adı:</strong> {storyData?.businessName || '-'}</p>
                      <p className="mb-2"><strong>Satıcı Adı:</strong> {storyData?.sellerName || '-'}</p>
                      <p className="mb-0"><strong>Medya Tipi:</strong> {storyData?.mediaType || '-'}</p>
                    </div>
                    <div className="col-md-6">
                      <p className="mb-2"><strong>Süre:</strong> {storyData?.duration || '-'} saniye</p>
                      <p className="mb-0"><strong>Açıklama:</strong> {storyData?.caption || '-'}</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Seçili Ürünler */}
              <div className="mb-4">
                <div className="d-flex align-items-center mb-3">
                  <i className="bi bi-box me-2"></i>
                  <h6 className="m-0">Seçili Ürünler ({validProducts.length})</h6>
                </div>
                
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead className="table-light">
                      <tr>
                        <th>No</th>
                        <th>Görsel</th>
                        <th>Ürün Adı</th>
                        <th>Kod</th>
                        <th>Fiyat</th>
                        <th>Kargo</th>
                        <th>Toplam</th>
                      </tr>
                    </thead>
                    <tbody>
                      {validProducts.map((product, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {product.mainImage ? (
                              <img 
                                src={product.mainImage} 
                                alt={product.productName}
                                style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                                className="rounded"
                              />
                            ) : '-'}
                          </td>
                          <td>{product.productName || '-'}</td>
                          <td>{product.productCode || '-'}</td>
                          <td>₺{Number(product.price || 0).toFixed(2)}</td>
                          <td>₺{Number(product.shippingCharges || 0).toFixed(2)}</td>
                          <td>₺{(Number(product.price || 0) + Number(product.shippingCharges || 0)).toFixed(2)}</td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot className="table-light">
                      <tr>
                        <td colSpan="6" className="text-end fw-bold">Toplam:</td>
                        <td className="fw-bold">
                          ₺{validProducts.reduce((sum, product) => (
                            sum + (Number(product.price) || 0) + (Number(product.shippingCharges) || 0)
                          ), 0).toFixed(2)}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductsModal;