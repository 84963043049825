import * as actionType from "./plan.type";
import { apiInstanceFetch } from "../../util/api";
import axios from "axios";
import { setToast } from "../../util/toast";

export const fetchPlanList = () => (dispatch) => {
  apiInstanceFetch.get(`plan/getPlans`).then((res) => {
    dispatch({
      type: actionType.GET_PLAN_LIST,
      payload: res.data || [],
    });
  });
};

export const createPlan = (plan) => (dispatch) => {
  return axios.post(`plan/createPlan`, plan)
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: actionType.CREATE_PLAN,
          payload: res.data.plan
        });
        setToast(res.data.message || "Plan başarıyla oluşturuldu", "success");
        return res.data;
      } else {
        setToast(res.data.message || "Plan oluşturulamadı", "error");
        throw new Error(res.data.message);
      }
    })
    .catch((error) => {
      console.error('Create error:', error.response?.data || error);
      setToast(
        error.response?.data?.message || 
        error.message || 
        "Plan oluşturulurken bir hata oluştu", 
        "error"
      );
      throw error;
    });
};

export const updatePlan = ({ id, data }) => (dispatch) => {
  const requestData = {
    planId: id,
    ...data
  };

  console.log('Request Data:', requestData);  // Debug için

  return axios.post(`plan/updatePlan`, requestData)
    .then((res) => {
      console.log('Response:', res.data);  // Debug için
      
      if (res.data.success) {
        setToast(res.data.message || "Plan başarıyla güncellendi", "success");
        dispatch({
          type: actionType.UPDATE_PLAN,
          payload: res.data.plan
        });
        return res.data;
      } else {
        setToast(res.data.message || "Güncelleme başarısız", "error");
        throw new Error(res.data.message);
      }
    })
    .catch((error) => {
      console.error('Update error:', error.response?.data || error);
      setToast(
        error.response?.data?.message || 
        error.message || 
        "Güncelleme sırasında bir hata oluştu", 
        "error"
      );
      throw error;
    });
};

export const deletePlan = (id) => (dispatch) => {
  return axios.delete(`plan/deletePlan/${id}`)
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: actionType.DELETE_PLAN,
          payload: id,
        });
        setToast(res.data.message || "Plan başarıyla silindi", "success");
        
        return dispatch(fetchPlanList());
      } else {
        setToast(res.data.message || "Plan silinemedi", "error");
        throw new Error(res.data.message);
      }
    })
    .catch((error) => {
      console.error('Delete error:', error.response?.data || error);
      setToast(
        error.response?.data?.message || 
        error.message || 
        "Plan silinirken bir hata oluştu", 
        "error"
      );
      throw error;
    });
};