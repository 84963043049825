import * as ActionType from "./sellerAccountType.type";

const initialState = {
  sellerAccountTypes: [],
};


export const sellerAccountTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_SELLER_ACCOUNT_TYPES:
      return { 
        ...state, 
        sellerAccountTypes: action.payload
     };
     case ActionType.CREATE_SELLER_ACCOUNT_TYPE:
      let data = [...state.sellerAccountTypes];
      data.unshift(action.payload);
      return {
        ...state,
        sellerAccountTypes: data,
      };
    default:
      return state;
  }
};
