// SellerAccountType.js
import React, { useState, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../extra/Table";
import Button from "../../extra/Button";
import Pagination from "../../extra/Pagination";
import { fetchSellerAccountTypes, deleteSellerAccountType } from "../../store/sellerAccounType/sellerAccountType.action";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import { warning } from "../../util/Alert";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Searching from "../../extra/Searching";
import SellerAccountTypeDialog from "./SellerAccountTypeDialog";

const useSellerAccountTypeData = () => {
  const dispatch = useDispatch();
  const accountTypeState = useSelector((state) => state.sellerAccountType);
  const refreshData = useCallback(() => {
    dispatch(fetchSellerAccountTypes());
  }, [dispatch]);

  React.useEffect(() => {
    refreshData();
  }, [refreshData]);

  return {
    accountTypes: accountTypeState?.sellerAccountTypes || [],
    loading: accountTypeState?.loading || false,
    error: accountTypeState?.error || null,
    refreshData
  };
};

const ActionButtons = ({ onEdit, onDelete }) => (
  <div className="d-flex gap-2 justify-content-center">
    <Button
      newClass="themeFont boxCenter userBtn fs-5"
      btnIcon="fa-solid fa-edit"
      style={{
        borderRadius: "5px",
        width: "40px",
        backgroundColor: "#fff",
        color: "#160d98",
      }}
      onClick={onEdit}
    />
    <Button
      newClass="themeFont boxCenter userBtn fs-5"
      btnIcon="fa-solid fa-trash"
      style={{
        borderRadius: "5px",
        width: "40px",
        backgroundColor: "#fff",
        color: "#cd2c2c",
      }}
      onClick={onDelete}
    />
  </div>
);

const SellerAccountType = () => {
 const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { accountTypes, loading, error, refreshData } = useSellerAccountTypeData();
  const { dialogue } = useSelector((state) => state.dialogue);

  const filteredData = useMemo(() => {
    if (!Array.isArray(accountTypes)) return [];
    if (!search) return accountTypes;

    const searchLower = search.toLowerCase();
    return accountTypes.filter((item) => {
      const type = item?.type?.toLowerCase() || "";
      const description = item?.description?.toLowerCase() || "";
      return type.includes(searchLower) || description.includes(searchLower);
    });
  }, [accountTypes, search]);

  const paginatedData = useMemo(() => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return filteredData.slice(startIndex, endIndex);
  }, [filteredData, page, rowsPerPage]);

  const handleFilterData = useCallback((searchValue) => {
    if (typeof searchValue === "string") {
      setSearch(searchValue.toLowerCase());
      setPage(0);
    }
  }, []);

  const handleDelete = useCallback(async (id) => {
    try {
      const shouldDelete = await warning();
      if (!shouldDelete || loading) return;

      const response = await dispatch(deleteSellerAccountType(id)).unwrap();
      if (response) {
        refreshData();
        setPage(0);
      }
    } catch (error) {
      console.error('Delete failed:', error);
    }
  }, [dispatch, loading, refreshData, setPage]);

  const handleOpenDialog = useCallback((data = null) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: { 
        type: "sellerAccountType",
        data: data ? {
          _id: data._id,
          type: data.type,
          description: data.description,
          commission_rate: data.commission_rate,
          transaction_fee: data.transaction_fee,
          currency: data.currency,
          sellerAccountType: data.sellerAccountType,
          additional_fees: data.additional_fees || []
        } : null
      },
    });
  }, [dispatch]);

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  }, []);

  const columns = useMemo(() => [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => <span>{page * rowsPerPage + index + 1}</span>,
    },
    {
      Header: "Type",
      body: "type",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0 text-capitalize">{row?.type || "-"}</p>
        </div>
      ),
    },
    {
      Header: "Description",
      body: "description",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0 text-truncate" title={row?.description}>
            {row?.description || "-"}
          </p>
        </div>
      ),
    },
    {
      Header: "Commission Rate",
      body: "commission_rate",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">{`${row?.commission_rate || 0}%`}</p>
        </div>
      ),
    },
    {
      Header: "Transaction Fee",
      body: "transaction_fee",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">{`${row?.transaction_fee || 0} ${row?.currency || ''}`}</p>
        </div>
      ),
    },
    {
        Header: "Additional Fees",
        body: "additional_fees",
        Cell: ({ row }) => (
          <div className="text-nowrap">
            {row?.additional_fees?.length > 0 ? (
              <div className="d-flex flex-column">
                {row.additional_fees.map((fee, index) => (
                  <small key={index} className="text-muted">
                    {fee.type}: {fee.description}
                  </small>
                ))}
              </div>
            ) : (
              <span className="text-muted">-</span>
            )}
          </div>
        ),
      },
    {
      Header: "Actions",
      body: "",
      Cell: ({ row }) => (
        <ActionButtons 
          onEdit={() => handleOpenDialog(row)} 
          onDelete={() => handleDelete(row._id)}
        />
      ),
    },
  ], [page, rowsPerPage, handleDelete, handleOpenDialog]);

  if (loading) {
    return (
      <div className="p-4">
        <Skeleton count={5} height={40} className="mb-2" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center p-4 text-danger">
        <p className="mb-0">Error: {error}</p>
      </div>
    );
  }

  return (
    <div className="mainSellerTable">
      <div className="sellerTable">
        <div className="sellerHeader primeHeader">
          <div className="row">
            <div className="col-4">
              <Button
                newClass="whiteFont"
                btnColor="btnBlackPrime"
                btnIcon="fa-solid fa-plus"
                btnName="Add Account Type"
                onClick={() => handleOpenDialog()}
                style={{ borderRadius: "10px" }}
              />
              {dialogue && <SellerAccountTypeDialog refreshData={refreshData} />}
            </div>
            <div className="col-8">
              <Searching
                type="client"
                data={accountTypes}
                column={columns}
                onFilterData={handleFilterData}
                serverSearching={handleFilterData}
                button={true}
                setData={setData}
                setSearchValue={setSearch}
                searchValue={search}
              />
            </div>
          </div>
        </div>

        <div className="sellerMain">
          <div className="tableMain mt-2 brandTable">
            {paginatedData.length === 0 ? (
              <div className="text-center p-4">Account type not found</div>
            ) : (
              <>
                <Table
                  data={paginatedData}
                  mapData={columns}
                  PerPage={rowsPerPage}
                  Page={page}
                  type="client"
                />
                <Pagination
                  component="div"
                  count={filteredData.length}
                  serverPage={page}
                  type="client"
                  onPageChange={handleChangePage}
                  serverPerPage={rowsPerPage}
                  totalData={filteredData.length}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
          </div>
        </div>
        <div className="ProductFooter primeFooter" />
      </div>
    </div>
  );
};

export default SellerAccountType;