// SubscriptionModal.js
import React from "react";

const modalStyle = {
  modal: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 1050,
    overflowX: "hidden",
    overflowY: "auto",
    outline: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalDialog: {
    position: "relative",
    pointerEvents: "auto",
    zIndex: 1051,
    maxWidth: "500px",
    margin: "1.75rem auto",
  },
  modalContent: {
    position: "relative",
    backgroundColor: "#fff",
    borderRadius: "0.3rem",
    outline: 0,
    boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
    borderBottom: "1px solid #dee2e6",
  },
  modalBody: {
    padding: "1rem",
  },
  modalFooter: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "1rem",
    borderTop: "1px solid #dee2e6",
  },
  detailItem: {
    padding: "0.5rem 0",
    borderBottom: "1px solid #f0f0f0",
  },
  closeButton: {
    padding: "0.5rem 1rem",
    backgroundColor: "#dc3545",
    color: "white",
    border: "none",
    borderRadius: "0.25rem",
    cursor: "pointer",
  },
  btnClose: {
    background: "transparent",
    border: "none",
    fontSize: "1.5rem",
    cursor: "pointer",
  },
};

const SubscriptionModal = ({ show, onHide, subscription }) => {
  if (!subscription) return null;

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onHide();
    }
  };

  return (
    <div
      style={{
        ...modalStyle.modal,
        display: show ? "block" : "none",
      }}
      onClick={handleBackdropClick}
    >
      <div style={modalStyle.modalDialog}>
        <div style={modalStyle.modalContent}>
          <div style={modalStyle.modalHeader}>
            <h5>Subscription Details</h5>
            <button 
              style={modalStyle.btnClose}
              onClick={onHide}
              aria-label="Close"
            >
              ×
            </button>
          </div>
          
          <div style={modalStyle.modalBody}>
            <div>
             
            
              <div style={modalStyle.detailItem}>
                <strong>Plan:</strong>{" "}
                {subscription?.planId?.title || "-"} 
              </div>
             
              <div style={modalStyle.detailItem}>
                <strong>Status:</strong>{" "}
                {subscription?.isActive ? "Active" : "Inactive"}
              </div>
              <div style={modalStyle.detailItem}>
                <strong>Price:</strong> {subscription?.price || "0"} kr
              </div>
         
              
            </div>
          </div>

          <div style={modalStyle.modalFooter}>
            <button 
              style={modalStyle.closeButton}
              onClick={onHide}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionModal;