import axios from 'axios';
import { apiInstanceFetch } from '../../util/api';
import {
  GET_CREDITS,
  GET_CREDITS_SUCCESS,
  GET_CREDITS_FAIL,
  CREATE_CREDIT,
  CREATE_CREDIT_SUCCESS,
  CREATE_CREDIT_FAIL,
  UPDATE_CREDIT,
  UPDATE_CREDIT_SUCCESS,
  UPDATE_CREDIT_FAIL,
  DELETE_CREDIT,
  DELETE_CREDIT_SUCCESS,
  DELETE_CREDIT_FAIL
} from './credit.type';

const handleApiError = (error, defaultMessage) => {
  return error?.response?.data?.message || error?.message || defaultMessage;
};

export const getCredits = () => async (dispatch) => {
  try {
    dispatch({ type: GET_CREDITS });
    
    const response = await apiInstanceFetch.get('credit');
    
    if (response?.data) {
      dispatch({
        type: GET_CREDITS_SUCCESS,
        payload: {
          data: response.data,
          total: response.data.length
        }
      });
    } else {
      throw new Error('Krediler alınamadı');
    }
  } catch (error) {
    dispatch({
      type: GET_CREDITS_FAIL,
      payload: handleApiError(error, 'Krediler alınamadı')
    });
  }
};

export const createCredit = (creditData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_CREDIT });
    
    const response = await axios.post('credit', creditData);
    
    if (response?.data) {
      dispatch({
        type: CREATE_CREDIT_SUCCESS,
        payload: response.data
      });
      return true;
    } else {
      throw new Error('Kredi oluşturulamadı');
    }
  } catch (error) {
    dispatch({
      type: CREATE_CREDIT_FAIL,
      payload: handleApiError(error, 'Kredi oluşturulamadı')
    });
    return false;
  }
};

export const updateCredit = (creditId, creditData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CREDIT });
    
    const response = await axios.put(`credit/${creditId}`, creditData);
    
    if (response?.data) {
      dispatch({
        type: UPDATE_CREDIT_SUCCESS,
        payload: response.data
      });
      return true;
    } else {
      throw new Error('Kredi güncellenemedi');
    }
  } catch (error) {
    dispatch({
      type: UPDATE_CREDIT_FAIL,
      payload: handleApiError(error, 'Kredi güncellenemedi')
    });
    return false;
  }
};

export const deleteCredit = (creditId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CREDIT });
    
    const response = await axios.delete(`credit/${creditId}`);
    
    if (response?.data) {
      dispatch({
        type: DELETE_CREDIT_SUCCESS,
        payload: creditId
      });
      return true;
    } else {
      throw new Error('Kredi paketi silinemedi');
    }
  } catch (error) {
    dispatch({
      type: DELETE_CREDIT_FAIL,
      payload: handleApiError(error, 'Kredi paketi silinirken bir hata oluştu')
    });
    return false;
  }
};