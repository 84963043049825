export const GET_CREDIT_INVOICES = 'GET_CREDIT_INVOICES';
export const GET_CREDIT_INVOICES_SUCCESS = 'GET_CREDIT_INVOICES_SUCCESS';
export const GET_CREDIT_INVOICES_FAIL = 'GET_CREDIT_INVOICES_FAIL';

export const CREATE_CREDIT_INVOICE = 'CREATE_CREDIT_INVOICE';
export const CREATE_CREDIT_INVOICE_SUCCESS = 'CREATE_CREDIT_INVOICE_SUCCESS';
export const CREATE_CREDIT_INVOICE_FAIL = 'CREATE_CREDIT_INVOICE_FAIL';

export const UPDATE_CREDIT_INVOICE = 'UPDATE_CREDIT_INVOICE';
export const UPDATE_CREDIT_INVOICE_SUCCESS = 'UPDATE_CREDIT_INVOICE_SUCCESS';
export const UPDATE_CREDIT_INVOICE_FAIL = 'UPDATE_CREDIT_INVOICE_FAIL';

export const DELETE_CREDIT_INVOICE = 'DELETE_CREDIT_INVOICE';
export const DELETE_CREDIT_INVOICE_SUCCESS = 'DELETE_CREDIT_INVOICE_SUCCESS';
export const DELETE_CREDIT_INVOICE_FAIL = 'DELETE_CREDIT_INVOICE_FAIL';
