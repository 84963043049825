import * as ActionType from "./subscription.type";

const initialState = {
  subscriptions: [],
};

export const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_SUBSCRIPTION:
      return {
        ...state,
        subscriptions: action.payload,
      };
    case ActionType.ADD_SUBSCRIPTION:
      let data = [...state.subscriptions];
      data.unshift(action.payload);
      return {
        ...state,
        subscriptions: data,
      };
    case ActionType.UPDATE_SUBSCRIPTION:
      return {
        ...state,
        subscriptions: state.subscriptions.map((subscription) =>
          subscription._id === action.payload.id ? action.payload.data : subscription
        ),
      };
    case ActionType.DELETE_SUBSCRIPTION:
      return {
        ...state,
        subscriptions: state.subscriptions.filter((subscription) => 
          subscription._id !== action.payload
        ),
      };
    default:
      return state;
  }
};