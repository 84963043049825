import {
    GET_CREDITS,
    GET_CREDITS_SUCCESS,
    GET_CREDITS_FAIL,
    CREATE_CREDIT,
    CREATE_CREDIT_SUCCESS,
    CREATE_CREDIT_FAIL,
    UPDATE_CREDIT,
    UPDATE_CREDIT_SUCCESS,
    UPDATE_CREDIT_FAIL,
    DELETE_CREDIT,
    DELETE_CREDIT_SUCCESS,
    DELETE_CREDIT_FAIL
  } from './credit.type';
  
  const initialState = {
    data: [],          
    total: 0,
    loading: false,
    error: null
  };
  
  export const creditReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_CREDITS:
      case CREATE_CREDIT:
      case UPDATE_CREDIT:
      case DELETE_CREDIT:
        return {
          ...state,
          loading: true,
          error: null
        };
      
      case GET_CREDITS_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload.data,         
          total: action.payload.total,
          error: null
        };
  
      case CREATE_CREDIT_SUCCESS:
        return {
          ...state,
          loading: false,
          data: [...state.data, action.payload],
          total: state.total + 1,
          error: null
        };
  
      case UPDATE_CREDIT_SUCCESS:
        return {
          ...state,
          loading: false,
          data: state.data.map(credit => 
            credit._id === action.payload._id ? action.payload : credit
          ),
          error: null
        };
  
      case DELETE_CREDIT_SUCCESS:
        return {
          ...state,
          loading: false,
          data: state.data.filter(credit => credit._id !== action.payload),
          total: state.total - 1,
          error: null
        };
      
      case GET_CREDITS_FAIL:
      case CREATE_CREDIT_FAIL:
      case UPDATE_CREDIT_FAIL:
      case DELETE_CREDIT_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      
      default:
        return state;
    }
  };