import {setToast} from "../../util/toast";
import * as ActionType from "./sellerAccountType.type";
import { apiInstanceFetch } from "../../util/api";
import axios from "axios";

export const fetchSellerAccountTypes = () => (dispatch) => {
  apiInstanceFetch.get(`sellerAccountType/getAccountTypes`).then((res) => {
   if (res) {
    dispatch({
      type: ActionType.GET_SELLER_ACCOUNT_TYPES,
        payload: res,
      });
    } else {
      setToast("error", res.error || "Seller Account Types yüklenemedi.");
    }
  })
  .catch((error) => {
    console.error("Hata:", error);
    setToast("error", "error while get seller account types.");
  });
};

export const deleteSellerAccountType = (id) => (dispatch) => {
  apiInstanceFetch.delete(`sellerAccountType/deleteSellerAccountType/${id}`).then((res) => {
    dispatch({
      type: ActionType.DELETE_SELLER_ACCOUNT_TYPE,
      payload: id,
    });
    setToast("success", "Seller Account Type deleted successfully.");
  });
};

export const createSellerAccountType = (formData) => (dispatch) => {
  return axios.post(`sellerAccountType/createSellerAccountType`, formData).then((res) => {
    dispatch({
      type: ActionType.CREATE_SELLER_ACCOUNT_TYPE,
      payload: res,
    });
    setToast("success", "Seller Account Type created successfully.");
  });
};

export const updateSellerAccountType = (formData) => (dispatch) => {
  return apiInstanceFetch.put(`sellerAccountType/updateSellerAccountType/${formData._id}`, formData)
    .then((res) => {
      if (res) {
        dispatch({
          type: ActionType.UPDATE_SELLER_ACCOUNT_TYPE,
          payload: res,
        });
        setToast("success", "Seller Account Type updated successfully.");
        return res;
      } else {
        setToast("error", res.error || "Update seller account type error.");
        throw new Error("update seller account type error");
      }
    })
    .catch((error) => {
      console.error("Update seller account type error:", error);
      setToast("error", "Update seller account type error.");
      throw error;
    });
};
