import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../extra/Table";
import Button from "../../extra/Button";
import Pagination from "../../extra/Pagination";
import { getOrderInvoiceRequests } from "../../store/orderInvoiceRequest/orderInvoiceRequest.action";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Searching from "../../extra/Searching";
import dayjs from "dayjs";

const OrderInvoiceRequest = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  
  const { requests, loading, error } = useSelector((state) => ({
    requests: state.orderInvoiceRequest?.requests || [],
    loading: state.orderInvoiceRequest?.loading || false,
    error: state.orderInvoiceRequest?.error || null
  }));

  useEffect(() => {
    dispatch(getOrderInvoiceRequests());
  }, [dispatch]);

  useEffect(() => {
    if (Array.isArray(requests)) {
      setFilteredData(requests);
    }
  }, [requests]);

  const handleFilterData = useCallback((searchValue) => {
    if (typeof searchValue !== "string") return;
    
    setSearch(searchValue);
    const searchLower = searchValue.toLowerCase();
    
    const filtered = requests.filter((item) => {
      const orderId = item?.orderId?.toString().toLowerCase() || "";
      const email = item?.email?.toLowerCase() || "";
      
      return orderId.includes(searchLower) || email.includes(searchLower);
    });
    
    setFilteredData(filtered);
  }, [requests]);

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  }, []);

  const handleViewInvoice = useCallback((url) => {
    if (!url) return;
    window.open(url, '_blank', 'noopener noreferrer');
  }, []);

  const mapData = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => <span>{index + 1}</span>,
    },
    {
      Header: "Order ID",
      body: "orderId",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">{row?.orderId || "-"}</p>
        </div>
      ),
    },
    {
      Header: "Email",
      body: "email",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">{row?.email || "-"}</p>
        </div>
      ),
    },
    {
      Header: "Status",
      body: "status", 
      Cell: ({ row }) => {
        const getStatusColor = (status) => {
          switch(status) {
            case 'Sent':
              return 'bg-success';
            case 'Rejected':
              return 'bg-danger';
            case 'Pending':
            default:
              return 'bg-warning';
          }
        };

        const getStatusText = (status) => {
          switch(status) {
            case 'Sent':
              return 'Sent';
            case 'Rejected':
              return 'Rejected';
            case 'Pending':
            default:
              return 'Pending';
          }
        };

        return (
          <div>
            <span className={`badge ${getStatusColor(row?.status)}`}>
              {getStatusText(row?.status)}
            </span>
          </div>
        );
      },
    },
    {
      Header: "Message",
      body: "message",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">{row?.message || "-"}</p>
        </div>
      ),
    },
    {
      Header: "Sent Date",
      body: "sentAt",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">
            {row?.sentAt ? dayjs(row.sentAt).format("DD/MM/YYYY HH:mm") : "-"}
          </p>
        </div>
      ),
    },
    {
      Header: "Invoice",
      body: "uploadedInvoiceUrl",
      Cell: ({ row }) => (
        row?.uploadedInvoiceUrl && (
          <Button
            newClass="themeFont boxCenter userBtn fs-5"
            btnIcon="fa-solid fa-eye"
            style={{
              borderRadius: "5px",
              margin: "auto",
              width: "40px",
              backgroundColor: "#fff",
              color: "#160d98",
            }}
            onClick={() => handleViewInvoice(row.uploadedInvoiceUrl)}
          />
        )
      ),
    },
  ];

  const renderContent = () => {
    if (loading) {
      return (
        <div className="p-4">
          <Skeleton count={5} height={40} className="mb-2" />
        </div>
      );
    }

    if (error) {
      return (
        <div className="text-center p-4">
          <p className="mb-0">Hata: {error}</p>
        </div>
      );
    }

    if (!Array.isArray(filteredData) || filteredData.length === 0) {
      return (
        <div className="text-center p-4">
          <p className="mb-0">Fatura talebi bulunamadı</p>
        </div>
      );
    }

    return (
      <>
        <Table
          data={filteredData}
          mapData={mapData}
          PerPage={rowsPerPage}
          Page={page}
          type="client"
        />
        <Pagination
          component="div"
          count={filteredData.length}
          serverPage={page}
          type="client"
          onPageChange={handleChangePage}
          serverPerPage={rowsPerPage}
          totalData={filteredData.length}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    );
  };

  return (
    <div className="mainSellerTable">
      <div className="sellerTable">
        <div className="sellerHeader primeHeader">
          <div className="row">
            <div className="col-12">
              <Searching
                type="client"
                data={requests}
                onFilterData={handleFilterData}
                serverSearching={handleFilterData}
                button={true}
                setSearchValue={setSearch}
                searchValue={search}
                placeholder="Sipariş ID veya Email ile ara"
              />
            </div>
          </div>
        </div>

        <div className="sellerMain">
          <div className="tableMain mt-2 brandTable">
            {renderContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderInvoiceRequest;