import { apiInstanceFetch } from '../../util/api';
import {
  GET_RETURN_ORDERS,
  GET_RETURN_ORDERS_SUCCESS,
  GET_RETURN_ORDERS_FAIL,
  UPDATE_RETURN_ORDER_STATUS,
  UPDATE_RETURN_ORDER_STATUS_SUCCESS,
  UPDATE_RETURN_ORDER_STATUS_FAIL,
} from './returnOrder.type';

export const getReturnOrders = () => async (dispatch) => {
  try {
    dispatch({ type: GET_RETURN_ORDERS });
    const response = await apiInstanceFetch.get('returnOrder/getReturnOrders');
    dispatch({
      type: GET_RETURN_ORDERS_SUCCESS,
      payload: response.returnOrders,
    });
  } catch (error) {
    console.error('API Error:', error);
    dispatch({
      type: GET_RETURN_ORDERS_FAIL,
      payload: error.message,
    });
  }
};

export const updateReturnOrderStatus = (orderId, status) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_RETURN_ORDER_STATUS });
    const response = await apiInstanceFetch.put(`return-orders/${orderId}/status`, { status });
    dispatch({
      type: UPDATE_RETURN_ORDER_STATUS_SUCCESS,
      payload: response.returnOrder,
    });
    dispatch(getReturnOrders());
  } catch (error) {
    console.error('API Error:', error);
    dispatch({
      type: UPDATE_RETURN_ORDER_STATUS_FAIL,
      payload: error.message,
    });
  }
};
