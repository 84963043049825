import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../extra/Table";
import Button from "../../extra/Button";
import Pagination from "../../extra/Pagination";
import { getCreditInvoices, deleteCreditInvoice } from "../../store/creditInvoice/creditInvoice.action";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import { warning } from "../../util/Alert";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Searching from "../../extra/Searching";

const CreditInvoice = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState([]);

  const invoices = useSelector((state) => state.creditInvoice?.invoices || []);
  const { dialogue } = useSelector((state) => state.dialogue);

  useEffect(() => {
    dispatch(getCreditInvoices());
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, [dispatch]);

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleDelete = async (id) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          dispatch(deleteCreditInvoice(id));
        }
      })
      .catch((err) => console.log(err));
  };

  const mapData = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => <span>{index + 1}</span>,
    },
    {
      Header: "User Name",
      body: "userId",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">
            {`${row.userId?.firstName || ""} ${row.userId?.lastName || ""}`}
          </p>
          <small className="text-muted">{row.userId?.email || ""}</small>
        </div>
      ),
    },
    {
      Header: "Package Information",
      body: "creditPackageId",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">{row.creditPackageId?.title || ""}</p>
          <small className="text-muted">
            {row.creditPackageId?.minutes || ""} minutes / {row.creditPackageId?.price || ""} kr
          </small>
        </div>
      ),
    },
    {
      Header: "Invoice Date",
      body: "purchaseDate",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">
            {new Date(row.purchaseDate).toLocaleDateString('en-US')}
          </p>
        </div>
      ),
    },
    {
      Header: "Amount",
      body: "totalAmount",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">{row.totalAmount || 0} kr</p>
        </div>
      ),
    },
    {
      Header: "PDF",
      body: "pdfUrl",
      Cell: ({ row }) => (
        <div>
          {row.pdfUrl && (
            <a 
              href={`https://vuunn.com/${row.pdfUrl}`} 
              target="_blank" 
              rel="noopener noreferrer"
              className="btn btn-sm btn-outline-primary"
            >
              <i className="fa-solid fa-file-pdf me-1"></i>
              Görüntüle
            </a>
          )}
        </div>
      ),
    },
   
  ];

  return (
    <div className="mainSellerTable">
      <div className="sellerTable">
        <div className="sellerHeader primeHeader">
          <div className="row">
            <div className="col-4">
            
            </div>
            <div className="col-8">
              <Searching
               type="client"
               data={invoices}
               setData={setData}
               column={data}
               onFilterData={handleFilterData}
               serverSearching={handleFilterData}
               button={true}
               setSearchValue={setSearch}
               searchValue={search}
              />
            </div>
          </div>
        </div>

        <div className="sellerMain">
          <div className="tableMain mt-2 brandTable">
            {loading ? (
              <div className="p-4">
                <Skeleton count={5} height={40} className="mb-2" />
              </div>
            ) : invoices.length === 0 ? (
              <div className="text-center p-4">
                <p className="mb-0">Kredi faturası bulunamadı</p>
              </div>
            ) : (
              <>
                <Table
                  data={invoices}
                  mapData={mapData}
                  PerPage={rowsPerPage}
                  Page={page}
                  type="client"
                />
                <Pagination
                  component="div"
                  count={invoices.length}
                  serverPage={page}
                  type="client"
                  onPageChange={handleChangePage}
                  serverPerPage={rowsPerPage}
                  totalData={invoices.length}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
          </div>
        </div>
        <div className="ProductFooter primeFooter"></div>
      </div>
    </div>
  );
};

export default CreditInvoice;
