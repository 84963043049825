export const GET_TERMS = 'GET_TERMS';
export const GET_TERMS_SUCCESS = 'GET_TERMS_SUCCESS';
export const GET_TERMS_FAIL = 'GET_TERMS_FAIL';

export const CREATE_TERM = 'CREATE_TERM';
export const CREATE_TERM_SUCCESS = 'CREATE_TERM_SUCCESS';
export const CREATE_TERM_FAIL = 'CREATE_TERM_FAIL';

export const UPDATE_TERM = 'UPDATE_TERM';
export const UPDATE_TERM_SUCCESS = 'UPDATE_TERM_SUCCESS';
export const UPDATE_TERM_FAIL = 'UPDATE_TERM_FAIL';

export const DELETE_TERM = 'DELETE_TERM';
export const DELETE_TERM_SUCCESS = 'DELETE_TERM_SUCCESS';
export const DELETE_TERM_FAIL = 'DELETE_TERM_FAIL';
