import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInfluencerList } from '../../store/influencer/influencer.action';
import Table from "../../extra/Table";
import Button from "../../extra/Button";
import Pagination from "../../extra/Pagination";
import Searching from "../../extra/Searching";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Influencer = () => {
    const dispatch = useDispatch();
    const [search, setSearch] = useState("");
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);

    const influencers = useSelector((state) => state.influencer.influencers || []);

    useEffect(() => {
        dispatch(fetchInfluencerList());
    }, [dispatch]);

    useEffect(() => {
        setData(influencers);
        setLoading(false);
    }, [influencers]);

    const mapData = [
        {
            Header: "No",
            width: "20px",
            Cell: ({ index }) => <span>{index + 1}</span>,
        },
        {
            Header: "Profile",
            width: "60px",
            Cell: ({ row }) => (
                <img 
                    src={row.userImage || "default-avatar.png"} 
                    alt="profile" 
                    className="rounded-circle"
                    style={{ width: "40px", height: "40px" }}
                />
            ),
        },
        {
            Header: "Name",
            body: "name",
            Cell: ({ row }) => (
                <div>
                    <p className="mb-0 text-capitalize">{row.name}</p>
                    <small className="text-muted">{row.location}</small>
                </div>
            ),
        },
        {
            Header: "Contact",
            Cell: ({ row }) => (
                <div>
                    <p className="mb-0">{row.email}</p>
                    <small className="text-muted">{row.mobileNumber}</small>
                </div>
            ),
        },
        {
            Header: "Social Media",
            Cell: ({ row }) => (
                <div className="d-flex gap-2">
                    {row.socialMediaLinks.map((social, index) => {
                        let baseUrl = '';
                        let username = '';
                        
                        if (social.url) {
                            switch(social.platform) {
                                case 'Instagram':
                                    baseUrl = 'https://instagram.com/';
                                    username = social.url.split('instagram.com/').pop();
                                    break;
                                case 'Twitter':
                                    baseUrl = 'https://twitter.com/';
                                    username = social.url.split('twitter.com/').pop();
                                    break;
                                case 'Youtube':
                                    baseUrl = 'https://youtube.com/';
                                    username = social.url.split('youtube.com/').pop();
                                    break;
                                case 'TikTok':
                                    baseUrl = 'https://tiktok.com/@';
                                    username = social.url.split('tiktok.com/@').pop();
                                    break;
                                default:
                                    baseUrl = social.url;
                            }
                        }

                        return (
                            <a 
                                key={social._id || index}
                                href={baseUrl + username}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-decoration-none"
                                title={username}
                            >
                                <i className={`fab fa-${social.platform.toLowerCase()}`}></i>
                            </a>
                        );
                    })}
                </div>
            ),
        },
        {
            Header: "Followers/Following",
            Cell: ({ row }) => (
                <div>
                    <span className="badge bg-primary me-2">
                        <i className="fas fa-users me-1"></i>
                        {row.followers}
                    </span>
                    <span className="badge bg-secondary">
                        <i className="fas fa-user-plus me-1"></i>
                        {row.following}
                    </span>
                </div>
            ),
        },
        {
            Header: "Credits",
            body: "liveStreamCredits",
            Cell: ({ row }) => (
                <span className="badge bg-info">
                    {row.liveStreamCredits}
                </span>
            ),
        },
        {
            Header: "Minimum Commission",
            body: "minimumCommissionRate",
            Cell: ({ row }) => (
                <span className="badge bg-warning">
                    %{row.minimumCommissionRate}
                </span>
            ),
        },
        {
            Header: "Status",
            body: "isActive",
            Cell: ({ row }) => (
                <span className={`badge ${row.isActive ? 'bg-success' : 'bg-danger'}`}>
                    {row.isActive ? 'Active' : 'Inactive'}
                </span>
            ),
        },
    ];

    if (loading) {
        return (
            <div className="p-4">
                <Skeleton count={5} height={40} className="mb-2" />
            </div>
        );
    }

    return (
        <div className="mainSellerTable">
            <div className="sellerTable">
                <div className="sellerHeader primeHeader">
                    <div className="row">
                        <div className="col-4">
                          
                        </div>
                        <div className="col-8">
                            <Searching
                                type="client"
                                data={influencers}
                                setData={setData}
                                searchKeys={["name", "email", "location"]}
                                button={true}
                                setSearchValue={setSearch}
                                searchValue={search}
                            />
                        </div>
                    </div>
                </div>

                <div className="sellerMain">
                    <div className="tableMain mt-2 brandTable">
                        {data.length === 0 ? (
                            <div className="text-center p-4">Influencer not found</div>
                        ) : (
                            <>
                                <Table
                                    data={data}
                                    mapData={mapData}
                                    PerPage={rowsPerPage}
                                    Page={page}
                                    type="client"
                                />
                                <Pagination
                                    component="div"
                                    count={data.length}
                                    serverPage={page}
                                    type="client"
                                    totalData={data.length}
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Influencer;