import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Table from "../../extra/Table";
import Button from "../../extra/Button";
import Pagination from "../../extra/Pagination";
import { getTerms, deleteTerm } from "../../store/termAndCondition/termAndCondition.action";
import { warning } from "../../util/Alert";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Searching from "../../extra/Searching";

const TermAndCondition = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const terms = useSelector((state) => state.termAndCondition?.terms || []);

  useEffect(() => {
    dispatch(getTerms());
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, [dispatch]);

  useEffect(() => {
    setData(terms);
  }, [terms]);

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleDelete = async (id) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          dispatch(deleteTerm(id));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleEdit = (row) => {
    navigate("/admin/termAndConditionEdit", {
      state: row,
    });
  };

  const handleAdd = () => {
    navigate("/admin/termAndConditionEdit");
  };

  const mapData = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => <span>{index + 1}</span>,
    },
    {
      Header: "Title",
      body: "title",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">{row.title || ""}</p>
        </div>
      ),
    },
    {
      Header: "Content",
      body: "description",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0" style={{ maxWidth: "400px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            {row.description || ""}
          </p>
        </div>
      ),
    },
    {
      Header: "Edit",
      body: "",
      Cell: ({ row }) => (
        <Button
          newClass="themeFont boxCenter userBtn fs-5"
          btnIcon="fa-solid fa-edit"
          style={{
            borderRadius: "5px",
            margin: "auto",
            width: "40px",
            backgroundColor: "#fff",
            color: "#160d98",
          }}
          onClick={() => handleEdit(row)}
        />
      ),
    },
    {
      Header: "Delete",
      body: "",
      Cell: ({ row }) => (
        <button
          className="themeBtn text-center"
          style={{
            borderRadius: "5px",
            margin: "auto",
            width: "40px",
            backgroundColor: "#fff",
            color: "#cd2c2c",
          }}
          onClick={() => handleDelete(row._id)}
        >
          <i className="fa-solid fa-trash"></i>
        </button>
      ),
    },
  ];

  return (
    <div className="mainSellerTable">
      <div className="sellerTable">
        <div className="sellerHeader primeHeader">
          <div className="row">
            <div className="col-4">
              <Button
                newClass="whiteFont"
                btnColor="btnBlackPrime"
                btnIcon="fa-solid fa-plus"
                btnName="Add Term"
                onClick={handleAdd}
                style={{ borderRadius: "10px" }}
              />
            </div>
            <div className="col-8">
              <Searching
                type="client"
                data={terms}
                setData={setData}
                onFilterData={handleFilterData}
                serverSearching={handleFilterData}
                button={true}
                setSearchValue={setSearch}
                searchValue={search}
              />
            </div>
          </div>
        </div>

        <div className="sellerMain">
          <div className="tableMain mt-2 brandTable">
            {loading ? (
              <div className="p-4">
                <Skeleton count={5} height={40} className="mb-2" />
              </div>
            ) : data.length === 0 ? (
              <div className="text-center p-4">
                <p className="mb-0">Koşul bulunamadı</p>
              </div>
            ) : (
              <>
                <Table
                  data={data}
                  mapData={mapData}
                  PerPage={rowsPerPage}
                  Page={page}
                  type="client"
                />
                <Pagination
                  component="div"
                  count={data.length}
                  serverPage={page}
                  type="client"
                  onPageChange={handleChangePage}
                  serverPerPage={rowsPerPage}
                  totalData={data.length}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
          </div>
        </div>
        <div className="ProductFooter primeFooter"></div>
      </div>
    </div>
  );
};

export default TermAndCondition;