import { apiInstanceFetch } from '../../util/api';
import * as ActionType from './invoiceInformation.type';

export const getInvoiceInformation = () => (dispatch) => {
    apiInstanceFetch.get('invoiceInformation/getAllInvoiceInformation')
        .then((res) => {
            dispatch({
                type: ActionType.GET_INVOICE_INFORMATION_SUCCESS,
                payload: res.invoiceInformations || [],
            });
        })
        .catch((error) => {
            console.error('Error:', error);
        });
};