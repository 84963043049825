// SellerAccountTypeDialog.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../extra/Button";
import Input from "../../extra/Input";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import { createSellerAccountType, updateSellerAccountType } from "../../store/sellerAccounType/sellerAccountType.action";

const SellerAccountTypeDialog = ({ refreshData }) => {
  const dispatch = useDispatch();
  const dialogueData = useSelector((state) => state.dialogue.dialogueData);

  const [mongoId, setMongoId] = useState(0);
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [commissionRate, setCommissionRate] = useState("");
  const [transactionFee, setTransactionFee] = useState("");
  const [currency, setCurrency] = useState("DKK");
  const [sellerAccountType, setSellerAccountType] = useState("privateSeller");
  const [additionalFees, setAdditionalFees] = useState([]);
  const [error, setError] = useState({
    type: "",
    description: "",
    commissionRate: "",
    transactionFee: ""
  });

  const [tempFeeType, setTempFeeType] = useState("");
  const [tempFeeDescription, setTempFeeDescription] = useState("");
  const [feeError, setFeeError] = useState("");

  useEffect(() => {
    if (dialogueData && dialogueData._id) {
      setMongoId(dialogueData._id);
      setType(dialogueData.type || "");
      setDescription(dialogueData.description || "");
      setCommissionRate(dialogueData.commission_rate?.toString() || "");
      setTransactionFee(dialogueData.transaction_fee?.toString() || "");
      setCurrency(dialogueData.currency || "DKK");
      setSellerAccountType(dialogueData.sellerAccountType || "privateSeller");
      setAdditionalFees(dialogueData.additional_fees || []);
    } else {
      handleReset();
    }
  }, [dialogueData]);

  const handleReset = () => {
    setMongoId(0);
    setType("");
    setDescription("");
    setCommissionRate("");
    setTransactionFee("");
    setCurrency("DKK");
    setSellerAccountType("privateSeller");
    setAdditionalFees([]);
    setTempFeeType("");
    setTempFeeDescription("");
    setError({});
    setFeeError("");
  };

  const handleClose = () => {
    handleReset();
    dispatch({ type: CLOSE_DIALOGUE });
  };

  const handleAddFee = () => {
    if (!tempFeeType.trim() || !tempFeeDescription.trim()) {
      setFeeError("Both type and description are required for additional fee");
      return;
    }

    setAdditionalFees(prev => [...prev, {
      type: tempFeeType.trim(),
      description: tempFeeDescription.trim()
    }]);
    setTempFeeType("");
    setTempFeeDescription("");
    setFeeError("");
  };

  const handleRemoveFee = (index) => {
    setAdditionalFees(prev => prev.filter((_, i) => i !== index));
  };

  const validateForm = () => {
    let newError = {};

    if (!type.trim()) newError.type = "Type is required!";
    if (!description.trim()) newError.description = "Description is required!";
    if (!commissionRate) newError.commissionRate = "Commission Rate is required!";
    if (!transactionFee) newError.transactionFee = "Transaction Fee is required!";

    setError(newError);
    return Object.keys(newError).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      const formData = {
        type: type.trim(),
        description: description.trim(),
        commission_rate: parseFloat(commissionRate),
        transaction_fee: parseFloat(transactionFee),
        currency,
        sellerAccountType,
        additional_fees: additionalFees
      };

      if (mongoId) {
        await dispatch(updateSellerAccountType({ ...formData, _id: mongoId }));
      } else {
        await dispatch(createSellerAccountType(formData));
      }

      refreshData?.();
      handleClose();
    } catch (err) {
      console.error("Error during submission:", err);
    }
  };

  return (
    <div className="mainDialogue fade-in">
      <div className="Dialogue">
        <div className="dialogueHeader">
          <div className="headerTitle fw-bold">
            {mongoId ? "Edit Account Type" : "Add Account Type"}
          </div>
          <div className="closeBtn" onClick={handleClose}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>

        <div className="dialogueMain">
          <div className="row">
            <div className="col-12 mb-3">
              <Input
                label="Type *"
                value={type}
                onChange={(e) => {
                  setType(e.target.value);
                  setError(prev => ({ ...prev, type: "" }));
                }}
                errorMessage={error.type}
                placeholder="Enter type"
              />
            </div>
            <div className="col-12 mb-3">
              <Input
                label="Description *"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                  setError(prev => ({ ...prev, description: "" }));
                }}
                errorMessage={error.description}
                placeholder="Enter description"
              />
            </div>
            <div className="col-12 mb-3">
              <Input
                label="Commission Rate *"
                type="number"
                step="0.01"
                min="0"
                value={commissionRate}
                onChange={(e) => {
                  setCommissionRate(e.target.value);
                  setError(prev => ({ ...prev, commissionRate: "" }));
                }}
                errorMessage={error.commissionRate}
                placeholder="Enter commission rate"
              />
            </div>
            <div className="col-12 mb-3">
              <Input
                label="Transaction Fee *"
                type="number"
                step="0.01"
                min="0"
                value={transactionFee}
                onChange={(e) => {
                  setTransactionFee(e.target.value);
                  setError(prev => ({ ...prev, transactionFee: "" }));
                }}
                errorMessage={error.transactionFee}
                placeholder="Enter transaction fee"
              />
            </div>

            <div className="col-12 mb-3">
              <h6 className="mb-3">Additional Fees</h6>
              
              {additionalFees.length > 0 && (
                <div className="mb-3">
                  {additionalFees.map((fee, index) => (
                    <div key={index} className="d-flex align-items-center mb-2 p-2 border rounded">
                      <div className="flex-grow-1">
                        <small className="d-block"><strong>Type:</strong> {fee.type}</small>
                        <small className="d-block"><strong>Description:</strong> {fee.description}</small>
                      </div>
                      <Button
                        btnIcon="fa-solid fa-trash"
                        btnColor="bg-danger text-white"
                        style={{ width: "32px", height: "32px" }}
                        onClick={() => handleRemoveFee(index)}
                      />
                    </div>
                  ))}
                </div>
              )}

              <div className="border rounded p-3">
                <div className="row">
                  <div className="col-12 mb-2">
                    <Input
                      label="Fee Type"
                      value={tempFeeType}
                      onChange={(e) => setTempFeeType(e.target.value)}
                      placeholder="Enter fee type"
                    />
                  </div>
                  <div className="col-12 mb-2">
                    <Input
                      label="Fee Description"
                      value={tempFeeDescription}
                      onChange={(e) => setTempFeeDescription(e.target.value)}
                      placeholder="Enter fee description"
                    />
                  </div>
                </div>
                {feeError && (
                  <small className="text-danger d-block mb-2">{feeError}</small>
                )}
                <Button
                  btnName="Add Fee"
                  btnIcon="fa-solid fa-plus"
                  btnColor="btnBlackPrime text-white"
                  onClick={handleAddFee}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="dialogueFooter">
          <div className="dialogueBtn">
            <Button
              btnName={mongoId ? "Update" : "Save"}
              btnColor="btnBlackPrime text-white"
              style={{ borderRadius: "5px", width: "80px" }}
              newClass="me-2"
              onClick={handleSubmit}
            />
            <Button
              btnName="Close"
              btnColor="bg-danger text-white"
              style={{ borderRadius: "5px", width: "80px" }}
              onClick={handleClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerAccountTypeDialog;