import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import { createFaQ, updateFaQ } from "../../store/FAQ/faq.action";
import Button from "../../extra/Button";
import Input from "../../extra/Input";

const FaqDialog = ({ createFaQ, updateFaQ }) => {
  const dispatch = useDispatch();
  const { dialogueData } = useSelector((state) => state.dialogue);

  // States
  const [formData, setFormData] = useState({
    mongoId: "",
    question: "",
    answer: "",
    userSide: false,
    sellerSide: false
  });

  const [errors, setErrors] = useState({
    question: "",
    answer: ""
  });

  // Destructure form data
  const { mongoId, question, answer, userSide, sellerSide } = formData;

  // Load data when editing
  useEffect(() => {
    if (dialogueData) {
      setFormData({
        mongoId: dialogueData?._id || "",
        question: dialogueData?.question || "",
        answer: dialogueData?.answer || "",
        userSide: dialogueData?.userSide || false,
        sellerSide: dialogueData?.sellerSide || false
      });
    }
  }, [dialogueData]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));

    // Clear error when user types
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ""
      }));
    }
  };

  // Validate form
  const validateForm = () => {
    const newErrors = {};
    if (!question) newErrors.question = "Question is required!";
    if (!answer) newErrors.answer = "Answer is required!";
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = () => {
    if (validateForm()) {
      const data = {
        question,
        answer,
        userSide,
        sellerSide
      };

      if (mongoId) {
        updateFaQ(data, mongoId);
      } else {
        createFaQ(data);
      }
      
      handleClose();
    }
  };

  // Handle dialog close
  const handleClose = () => {
    dispatch({ type: CLOSE_DIALOGUE });
  };

  return (
    <div className="mainDialogue fade-in">
      <div className="Dialogue">
        {/* Header */}
        <div className="dialogueHeader">
          <div className="headerTitle fw-bold">FAQ</div>
          <div className="closeBtn" onClick={handleClose}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>

        {/* Main Content */}
        <div className="dialogueMain">
          {/* Question Input */}
          <div className="row mb-3">
            <div className="col-12">
              <Input
                label="Question"
                id="question"
                name="question"
                type="text"
                value={question}
                errorMessage={errors.question}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* Visibility Checkboxes */}
          <div className="row mb-3">
            <div className="col-md-6">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="userSide"
                  name="userSide"
                  checked={userSide}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="userSide">
                  Visible to Users
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sellerSide"
                  name="sellerSide"
                  checked={sellerSide}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="sellerSide">
                  Visible to Sellers
                </label>
              </div>
            </div>
          </div>

          {/* Answer Textarea */}
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label className="form-label">Answer</label>
                <textarea
                  className="form-control"
                  name="answer"
                  rows="5"
                  value={answer}
                  onChange={handleChange}
                  placeholder="Enter answer..."
                />
                {errors.answer && (
                  <div className="text-danger small mt-1">{errors.answer}</div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="dialogueFooter">
          <div className="dialogueBtn">
            <Button
              btnName={mongoId ? "Update" : "Submit"}
              btnColor="btnBlackPrime text-white"
              style={{ borderRadius: "5px", width: "80px" }}
              newClass="me-2"
              onClick={handleSubmit}
            />
            <Button
              btnName="Close"
              btnColor="bg-danger text-white"
              style={{ borderRadius: "5px", width: "80px" }}
              onClick={handleClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { createFaQ, updateFaQ })(FaqDialog);
