import {
  GET_TERMS,
  GET_TERMS_SUCCESS,
  GET_TERMS_FAIL,
  CREATE_TERM,
  CREATE_TERM_SUCCESS,
  CREATE_TERM_FAIL,
  UPDATE_TERM,
  UPDATE_TERM_SUCCESS,
  UPDATE_TERM_FAIL,
  DELETE_TERM,
  DELETE_TERM_SUCCESS,
  DELETE_TERM_FAIL
} from './termAndCondition.type';

const initialState = {
  terms: [],
  loading: false,
  error: null,
};

export const termAndConditionReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET
    case GET_TERMS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_TERMS_SUCCESS:
      return {
        ...state,
        loading: false,
        terms: action.payload,
      };
    case GET_TERMS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // CREATE
    case CREATE_TERM:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_TERM_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_TERM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // UPDATE
    case UPDATE_TERM:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_TERM_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_TERM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // DELETE
    case DELETE_TERM:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_TERM_SUCCESS:
      return {
        ...state,
        loading: false,
        terms: state.terms.filter((term) => term._id !== action.payload),
      };
    case DELETE_TERM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

