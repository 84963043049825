import { setToast } from "../../util/toast";
import * as ActionType from "./brand.type";
import { apiInstanceFetch } from "../../util/api";
import axios from "axios";




export const fetchBrands = () => (dispatch) => {
  apiInstanceFetch.get(`brand/getAllBrands`).then((res) => {
    if (res) {  
      dispatch({
        type: ActionType.GET_BRANDS,
        payload: res.brands,  
      });
    } else {
      setToast("error", res.error || "Markalar yüklenemedi.");
    }
  })
  .catch((error) => {
    console.error("Hata:", error);
    setToast("error", "error while get brand.");
  });
};

export const createBrand = (formData) => (dispatch) => {
  return axios
    .post(`brand/create`, formData)
    .then((res) => {
      if (res.data.success) {
        dispatch({
          type: ActionType.CREATE_BRAND,
          payload: res.data.brand,
        });
        setToast("success", "Brand Create successfully");
      } else {
        setToast("error", res.data.error || "Brand Create error");
      }
    })
    .catch((error) => {
      console.error("Hata:", error.message);
      setToast("error", "error while create brand.");
    });
};

export const updateBrand = (formData, id) => (dispatch) => {
  return axios
    .put(`brand/update/${id}`, formData)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: ActionType.UPDATE_BRAND,
          payload: { data: res.data.brand, id },
        });
        setToast("success", "Brand update successfully");
      } else {
        setToast("error", res.data.message || "Brand update error");
      }
    })
    .catch((error) => {
      console.error("Hata:", error.message);
      setToast("error", "Marka güncellenirken bir hata oluştu.");
    });
};

export const deleteBrand = (id) => (dispatch) => {
  console.log("DeleteBrand action başlatıldı, ID:", id);
  
  return axios
    .delete(`/brand/delete/${id}`)
    .then((res) => {
      console.log("API yanıtı:", res);
      
      if (res.data.status) {
        dispatch({
          type: ActionType.DELETE_BRAND,
          payload: id,
        });
        setToast("success", "Brand Delete successfully");
        return true;
      } else {
        setToast("error", res.data.message || "Brand delete error");
        return false;
      }
    })
    .catch((error) => {
      console.error("api delete error:", error);
      setToast("error", "error while delete brand.");
      throw error;
    });
};