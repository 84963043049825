import {
  GET_RETURN_ORDERS,
  GET_RETURN_ORDERS_SUCCESS,
  GET_RETURN_ORDERS_FAIL,
  UPDATE_RETURN_ORDER_STATUS,
  UPDATE_RETURN_ORDER_STATUS_SUCCESS,
  UPDATE_RETURN_ORDER_STATUS_FAIL,
} from './returnOrder.type';

const initialState = {
  loading: false,
  returnOrders: [],
  error: null,
};

export const returnOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RETURN_ORDERS:
    case UPDATE_RETURN_ORDER_STATUS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_RETURN_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        returnOrders: action.payload,
      };

    case UPDATE_RETURN_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        returnOrders: state.returnOrders.map(order => 
          order._id === action.payload._id ? action.payload : order
        ),
      };

    case GET_RETURN_ORDERS_FAIL:
    case UPDATE_RETURN_ORDER_STATUS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

