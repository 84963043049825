import {
  GET_ORDER_INVOICE_REQUESTS,
  GET_ORDER_INVOICE_REQUESTS_SUCCESS,
  GET_ORDER_INVOICE_REQUESTS_FAIL,
} from './orderInvoiceRequest.type';

const initialState = {
  requests: [], 
  loading: false,
  error: null,
};

export const orderInvoiceRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDER_INVOICE_REQUESTS:
      return {
        ...state,
        loading: true,
        error: null,
        requests: [],
      };
    case GET_ORDER_INVOICE_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        requests: Array.isArray(action.payload) ? action.payload : [],
        error: null,
      };
    case GET_ORDER_INVOICE_REQUESTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        requests: [],
      };
    default:
      return state;
  }
};