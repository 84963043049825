import React from 'react';
import './SellerDetailsModal.css';

const SellerDetailsModal = ({ show, onHide, sellerData }) => {
  if (!show) return null;

  return (
    <>
      <div className="custom-modal-overlay"></div>
      <div className="custom-modal">
        <div className="custom-modal-dialog">
          <div className="custom-modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Request Details</h5>
              <button type="button" className="btn-close" onClick={onHide}></button>
            </div>

            <div className="modal-body">
              <div className="mb-4">
                <div className="d-flex align-items-center mb-3">
                  <i className="bi bi-info-circle me-2"></i>
                  <h6 className="m-0">Basic Information</h6>
                </div>
                <div className="bg-light p-3 rounded">
                  <div className="row">
                    <div className="col-md-6">
                      <p className="mb-2"><strong>Business Name:</strong> {sellerData?.businessName || '-'}</p>
                      <p className="mb-2"><strong>Business Tag:</strong> {sellerData?.businessTag || '-'}</p>
                      <p className="mb-0"><strong>CVR Number:</strong> {sellerData?.sellerDetails?.cvr || '-'}</p>
                    </div>
                    <div className="col-md-6">
                      <p className="mb-2"><strong>Account Type:</strong> {sellerData?.accountType?.type || '-'}</p>
                      <p className="mb-2"><strong>Commission Rate:</strong> {sellerData?.accountType?.commission_rate || '0'}%</p>
                      <p className="mb-0"><strong>Transaction Fee:</strong> {sellerData?.accountType?.transaction_fee} {sellerData?.accountType?.currency}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <div className="d-flex align-items-center mb-3">
                  <i className="bi bi-file-earmark-text me-2"></i>
                  <h6 className="m-0">Documents</h6>
                </div>
                <div className="row g-3">
                  {sellerData?.sellerDetails?.documents?.identityDocuments?.length > 0 && (
                    <div className="col-md-4">
                      <div className="document-card">
                        <a 
                          href={sellerData.sellerDetails.documents.identityDocuments[0]} 
                          target="_blank" 
                          rel="noopener noreferrer"
                        >
                          <img 
                            src={sellerData.sellerDetails.documents.identityDocuments[0]} 
                            alt="Identity Document" 
                            className="img-fluid rounded"
                          />
                          <div className="document-label">
                            <i className="bi bi-person-vcard me-2"></i>
                            identityDocuments
                          </div>
                        </a>
                      </div>
                    </div>
                  )}

                  {sellerData?.sellerDetails?.documents?.cvrDocuments?.length > 0 && (
                    <div className="col-md-4">
                      <div className="document-card">
                        <a 
                          href={sellerData.sellerDetails.documents.cvrDocuments[0]} 
                          target="_blank" 
                          rel="noopener noreferrer"
                        >
                          <img 
                            src={sellerData.sellerDetails.documents.cvrDocuments[0]} 
                            alt="CVR Document" 
                            className="img-fluid rounded"
                          />
                          <div className="document-label">
                            <i className="bi bi-file-text me-2"></i>
                            cvrDocuments
                          </div>
                        </a>
                      </div>
                    </div>
                  )}

                  {sellerData?.sellerDetails?.documents?.companyDocuments?.length > 0 && (
                    <div className="col-md-4">
                      <div className="document-card">
                        <a 
                          href={sellerData.sellerDetails.documents.companyDocuments[0]} 
                          target="_blank" 
                          rel="noopener noreferrer"
                        >
                          <img 
                            src={sellerData.sellerDetails.documents.companyDocuments[0]} 
                            alt="Company Document" 
                            className="img-fluid rounded"
                          />
                          <div className="document-label">
                            <i className="bi bi-building me-2"></i>
                            companyDocuments
                          </div>
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {sellerData?.isInfluencer && (
                <div>
                  <div className="d-flex align-items-center mb-3">
                    <i className="bi bi-person-badge me-2"></i>
                    <h6 className="m-0">Influencer Details</h6>
                  </div>
                  <div className="bg-light p-3 rounded mb-3">
                    <p className="mb-0">
                      <strong>Minimum Commission Rate:</strong> {sellerData?.influencerDetails?.minimumCommissionRate}%
                    </p>
                  </div>
                  
                  {sellerData?.influencerDetails?.socialMediaLinks?.length > 0 && (
                    <div className="mt-3">
                      <div className="d-flex align-items-center mb-3">
                        <i className="bi bi-share me-2"></i>
                        <h6 className="m-0">Social Media Accounts</h6>
                      </div>
                      <div className="d-flex flex-wrap gap-2">
                        {sellerData?.influencerDetails?.socialMediaLinks.map((social, index) => (
                          <a
                            key={index}
                            href={social.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-light border"
                          >
                            <i className={`bi bi-${social.platform.toLowerCase()} me-2`}></i>
                            {social.platform}
                          </a>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SellerDetailsModal;