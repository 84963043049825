import { apiInstanceFetch } from '../../util/api';
import {
  GET_INFLUENCER_PARTNERSHIPS,
  GET_INFLUENCER_PARTNERSHIPS_SUCCESS,
  GET_INFLUENCER_PARTNERSHIPS_FAIL,
  CREATE_INFLUENCER_PARTNERSHIP,
  CREATE_INFLUENCER_PARTNERSHIP_SUCCESS,
  CREATE_INFLUENCER_PARTNERSHIP_FAIL,
  UPDATE_INFLUENCER_PARTNERSHIP,
  UPDATE_INFLUENCER_PARTNERSHIP_SUCCESS,
  UPDATE_INFLUENCER_PARTNERSHIP_FAIL,
  DELETE_INFLUENCER_PARTNERSHIP,
  DELETE_INFLUENCER_PARTNERSHIP_SUCCESS,
  DELETE_INFLUENCER_PARTNERSHIP_FAIL
} from './influencerPartnership.type';

export const getInfluencerPartnerships = () => async (dispatch) => {
  try {
    dispatch({ type: GET_INFLUENCER_PARTNERSHIPS });
    const response = await apiInstanceFetch.get('influencer/getAllPartnerships');
    console.log('API Response:', response.partnerships);
    dispatch({
      type: GET_INFLUENCER_PARTNERSHIPS_SUCCESS,
      payload: response.partnerships,
    });
  } catch (error) {
    console.error('API Error:', error);
    dispatch({
      type: GET_INFLUENCER_PARTNERSHIPS_FAIL,
      payload: error.message,
    });
  }
};

export const createInfluencerPartnership = (data) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_INFLUENCER_PARTNERSHIP });
    const response = await apiInstanceFetch.post('influencer/createPartnership', data);
    dispatch({
      type: CREATE_INFLUENCER_PARTNERSHIP_SUCCESS,
      payload: response.partnerships,
    });
    dispatch(getInfluencerPartnerships());
  } catch (error) {
    dispatch({
      type: CREATE_INFLUENCER_PARTNERSHIP_FAIL,
      payload: error.message,
    });
  }
};

export const updateInfluencerPartnership = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_INFLUENCER_PARTNERSHIP });
    const response = await apiInstanceFetch.put(`influencer/updatePartnership/${id}`, data);
    dispatch({
      type: UPDATE_INFLUENCER_PARTNERSHIP_SUCCESS,
      payload: response.partnerships,
    });
    dispatch(getInfluencerPartnerships()); 
  } catch (error) {
    dispatch({
      type: UPDATE_INFLUENCER_PARTNERSHIP_FAIL,
      payload: error.message,
    });
  }
};

export const deleteInfluencerPartnership = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_INFLUENCER_PARTNERSHIP });
    await apiInstanceFetch.delete(`influencer/deletePartnership/${id}`);
    dispatch({
      type: DELETE_INFLUENCER_PARTNERSHIP_SUCCESS,
      payload: id,
    });
    dispatch(getInfluencerPartnerships());
  } catch (error) {
    dispatch({
      type: DELETE_INFLUENCER_PARTNERSHIP_FAIL,
      payload: error.message,
    });
  }
};
