import axios from 'axios';
import { apiInstanceFetch } from '../../util/api';
import {
  GET_TERMS,
  GET_TERMS_SUCCESS,
  GET_TERMS_FAIL,
  CREATE_TERM,
  CREATE_TERM_SUCCESS,
  CREATE_TERM_FAIL,
  UPDATE_TERM,
  UPDATE_TERM_SUCCESS,
  UPDATE_TERM_FAIL,
  DELETE_TERM,
  DELETE_TERM_SUCCESS,
  DELETE_TERM_FAIL
} from './termAndCondition.type';

export const getTerms = () => async (dispatch) => {
  try {
    dispatch({ type: GET_TERMS });
    const response = await apiInstanceFetch.get('termAndConditions/get');
    dispatch({
      type: GET_TERMS_SUCCESS,
      payload: response.terms || [],
    });
  } catch (error) {
    dispatch({
      type: GET_TERMS_FAIL,
      payload: error.message,
    });
  }
};

export const createTerm = (data) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_TERM });
    const response = await axios.post('termAndConditions/create', data);
    dispatch({
      type: CREATE_TERM_SUCCESS,
      payload: response.terms,
    });
    dispatch(getTerms());
  } catch (error) {
    dispatch({
      type: CREATE_TERM_FAIL,
      payload: error.message,
    });
  }
};

export const updateTerm = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_TERM });
    const response = await axios.put(`terms/update/${id}`, data);
    dispatch({
      type: UPDATE_TERM_SUCCESS,
      payload: response.terms,
    });
    dispatch(getTerms());
  } catch (error) {
    dispatch({
      type: UPDATE_TERM_FAIL,
      payload: error.message,
    });
  }
};

export const deleteTerm = (termId) => async (dispatch) => {
    try {
      dispatch({ type: DELETE_TERM });
      await axios.delete(`termAndConditions/delete?termId=${termId}`);
      dispatch({
        type: DELETE_TERM_SUCCESS,
        payload: termId,
      });
      dispatch(getTerms()); // Listeyi yenile
    } catch (error) {
      dispatch({
        type: DELETE_TERM_FAIL,
        payload: error.message,
      });
    }
  };