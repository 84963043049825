import { apiInstanceFetch } from "../../util/api";
import { setToast } from "../../util/toast";
import * as ActionType from "./subscription.type";
import axios from "axios";

// Tüm abonelikleri getir
export const fetchSubscriptions = () => (dispatch) => {
  apiInstanceFetch
    .get('subscription/getAllSubscription')
    .then((res) => {
      if (res?.data) {
        dispatch({
          type: ActionType.GET_SUBSCRIPTION,
          payload: res.data 
        });
      }
    })
    .catch((error) => {
      console.error("Hata:", error);
      setToast("error", "Abonelikler yüklenirken hata oluştu.");
    });
};
// Yeni abonelik ekle
export const addSubscription = (subscriptionData) => async (dispatch) => {
  try {
    const response = await axios.post("/subscription/createSubscription", subscriptionData);
    
    dispatch({
      type: ActionType.ADD_SUBSCRIPTION,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ActionType.SUBSCRIPTION_ERROR,
      payload: error.message,
    });
  }
};

// Abonelik güncelle
export const updateSubscription = (id, subscriptionData) => async (dispatch) => {
  try {
    const response = await axios.put(`/subscription/updateSubscription/${id}`, subscriptionData);
    
    dispatch({
      type: ActionType.UPDATE_SUBSCRIPTION,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ActionType.SUBSCRIPTION_ERROR,
      payload: error.message,
    });
  }
};

// Abonelik sil
export const deleteSubscription = (id) => async (dispatch) => {
  try {
    await axios.delete(`/subscription/deleteSubscription/${id}`);
    
    dispatch({
      type: ActionType.DELETE_SUBSCRIPTION,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: ActionType.SUBSCRIPTION_ERROR,
      payload: error.message,
    });
  }
};
