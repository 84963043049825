import * as ActionType from './invoiceInformation.type';

const initialState = {
    invoiceInformation: []
};

export const invoiceInformationReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionType.GET_INVOICE_INFORMATION_SUCCESS:
            return {
                ...state,
                invoiceInformation: action.payload
            };
        default:
            return state;
    }
};