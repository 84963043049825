import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../extra/Table";
import Button from "../../extra/Button";
import Pagination from "../../extra/Pagination";
import { fetchStoryList, deleteStory } from "../../store/story/story.action";
import { warning } from "../../util/Alert";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Searching from "../../extra/Searching";
import { useNavigate } from "react-router-dom";
import ProductsModal from "./ProductModal";


const Story = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [showProductModal, setShowProductModal] = useState(false);
  const [selectedStoryData, setSelectedStoryData] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedStory, setSelectedStory] = useState(null);
  const stories = useSelector((state) => {
    console.log("Redux state:", state.story);
    return state.story?.story || [];
  });

  const handleShowProductModal = (storyData) => {
    setSelectedStoryData(storyData);
    setShowProductModal(true);
  };
  
  const handleCloseModal = () => {
    setShowProductModal(false);
    setSelectedStoryData(null);
  };

  useEffect(() => {
    dispatch(fetchStoryList());
  }, [dispatch]);

  useEffect(() => {
    setData(stories);
  }, [stories]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    console.log("Stories data:", stories);
  }, [stories]);

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleDelete = async (id) => {
    const data = warning();
    data.then((isDeleted) => {
      if (isDeleted) {
        dispatch(deleteStory(id));
      }
    }).catch((err) => console.log(err));
  };

  const mapData = [
 
    {
      Header: "Selected Products",
      accessor: "selectedProducts",
      Cell: ({ row: { original } }) => {
        const productCount = original?.selectedProducts?.length || 0;
        
        return (
          <button
            className="themeFont boxCenter userBtn"
            onClick={() => handleShowProductModal(original)}
            style={{
              borderRadius: "5px",
              margin: "auto",
              padding: "6px 12px",
              backgroundColor: "#fff",
              color: "#160d98",
              border: "1px solid #160d98"
            }}
          >
            <i className="fa-solid fa-eye me-2"></i>
            Ürünleri Gör ({productCount})
          </button>
        );
      }
    },
    {
      Header: "Business Name",
      accessor: "businessName",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">{row.businessName || "-"}</p>
        </div>
      ),
    },
    {
      Header: "Seller Name",
      accessor: "sellerName",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">{row.sellerName || "-"}</p>
        </div>
      ),
    },
    {
      Header: "Media Type",
      accessor: "mediaType",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">{row.mediaType || "-"}</p>
        </div>
      ),
    },
  {
    Header: "Duration",
    accessor: "duration",
    Cell: ({ row }) => (
      <div>
        <p className="mb-0">{row.duration || "-"}</p>
      </div>
    ),
  },
    {
      Header: "Caption",
      accessor: "caption",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">{row.caption || "-"}</p>
        </div>
      ),
    },
   
  ];

  if (!Array.isArray(stories)) {
    return (
      <div className="p-4">
        <Skeleton count={5} height={40} className="mb-2" />
      </div>
    );
  }

  return (
    <div className="mainSellerTable">
      <div className="sellerTable">
        <div className="sellerHeader primeHeader">
          <div className="row">
            <div className="col-4">
             
            </div>
            <div className="col-8">
              <Searching
                type="client"
                data={stories}
                setData={setData}
                column={data}
                onFilterData={handleFilterData}
                serverSearching={handleFilterData}
                button={true}
                setSearchValue={setSearch}
                searchValue={search}
              />
            </div>
          </div>
        </div>

        <div className="sellerMain">
          <div className="tableMain mt-2 storyTable">
            {data.length === 0 ? (
              <div className="text-center p-4">Story not found</div>
            ) : (
              <>
              <Table
  data={data}
  mapData={mapData}
  PerPage={rowsPerPage}
  Page={page}
  type="client"
/>
                <Pagination
                  component="div"
                  count={data.length}
                  serverPage={page}
                  type="client"
                  onPageChange={handleChangePage}
                  serverPerPage={rowsPerPage}
                  totalData={data.length}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
          </div>
        </div>
      </div>
          <ProductsModal 
          show={showProductModal}
          onHide={handleCloseModal}  
          products={selectedStoryData?.selectedProducts || []}
          storyData={selectedStoryData}
    />
    </div>
  );
};

export default Story;