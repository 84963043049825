import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from "react-loading-skeleton";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Button from "../../extra/Button";
import Pagination from "../../extra/Pagination";
import Searching from "../../extra/Searching";
import { getReturnOrders } from '../../store/returnOrder/returnOrder.action';
import Table from '../../extra/Table';
import ReturnOrderDialog from './ReturnOrderDialog';

const ReturnOrder = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const { loading, returnOrders } = useSelector((state) => state.returnOrder);
  const { dialogue } = useSelector((state) => state.dialogue);

  useEffect(() => {
    dispatch(getReturnOrders());
  }, [dispatch]);

  useEffect(() => {
    setData(returnOrders);
    setFilteredData(returnOrders);
  }, [returnOrders]);

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
      setFilteredData(filteredData);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const mapData = [
    {
      Header: 'Order No',
      body: 'orderId.orderId',
    },
    {
      Header: 'Product',
      body: 'productId.productName',
    },
    {
      Header: 'Customer',
      body: 'userId',
      Cell: ({ row }) => (
        <span>
          {`${row.userId?.firstName || ''} ${row.userId?.lastName || ''}`}
        </span>
      ),
    },
    {
      Header: 'Return Reason',
      body: 'reason',
    },
    {
      Header: 'Tracking No',
      body: 'trackingId',
      Cell: ({ row }) => (
        <span>
          {row.trackingId || '-'}
        </span>
      ),
    },
    {
      Header: 'Tracking Link',
      body: 'trackingLink',
      Cell: ({ row }) => (
        row.trackingLink ? (
          <a 
            href={row.trackingLink} 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-primary"
          >
            Track
          </a>
        ) : (
          <span>-</span>
        )
      ),
    },
    {
      Header: 'QR Code',
      body: 'qrCode',
      Cell: ({ row }) => {
        if (!row.qrCode) return <span>-</span>;
        
        return (
          <div style={{ cursor: 'pointer' }}>
            <img 
              src={`data:image/png;base64,${row.qrCode}`}
              alt="QR Code"
              style={{ 
                width: '50px', 
                height: '50px',
                objectFit: 'contain'
              }}
              onClick={() => {
                const win = window.open("", "_blank");
                win.document.write(`
                  <html>
                    <head>
                      <title>QR Code</title>
                    </head>
                    <body style="margin:0;display:flex;justify-content:center;align-items:center;background:#f5f5f5;">
                      <img 
                        src="data:image/png;base64,${row.qrCode}" 
                        style="max-width:100%;max-height:100vh;"
                      />
                    </body>
                  </html>
                `);
              }}
            />
          </div>
        );
      },
    },
   {
    Header: 'Status',
    body: 'status',
    Cell: ({ value }) => (
      <div>
        <span className={`badge ${
          value === "Initiated" ? "bg-info" :
          value === "Pending" ? "bg-warning" :
          value === "Completed" ? "bg-success" :
          value === "Rejected" ? "bg-danger" : 
          "bg-secondary"
        }`}>
          {value || "Initiated"}
        </span>
      </div>
    ),
   }
   
  ];

  return (
    <div className="mainSellerTable">
      <div className="sellerTable">
        <div className="sellerHeader primeHeader">
          <div className="row">
            <div className="col-4">
            
            </div>
            <div className="col-8">
              <Searching
                type="client"
                data={returnOrders}
                setData={setData}
                column={data}
                onFilterData={handleFilterData}
                button={true}
                setSearchValue={setSearch}
                searchValue={search}
              />
            </div>
          </div>
        </div>

        <div className="sellerMain">
          <div className="tableMain mt-2 returnOrderTable">
            {loading ? (
              <div className="p-4">
                <Skeleton count={5} height={40} className="mb-2" />
              </div>
            ) : filteredData && filteredData.length > 0 ? (
              <>
                <Table
                  data={filteredData}
                  mapData={mapData}
                  PerPage={rowsPerPage}
                  Page={page}
                  type="client"
                />
                <Pagination
                  component="div"
                  count={filteredData.length}
                  serverPage={page}
                  type="client"
                  onPageChange={handleChangePage}
                  serverPerPage={rowsPerPage}
                  totalData={filteredData.length}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : (
              <div className="text-center p-4">
                <h4>Return order not found</h4>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnOrder;
