import React, { useEffect, useState } from "react";
import Button from "../../extra/Button";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Input from "../../extra/Input";
import { useDispatch, useSelector } from "react-redux";
import { createInfluencerPartnership, updateInfluencerPartnership } from "../../store/InfluencerPartnership/influencerPartnership.action";

const InfluencerPartnershipDialog = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();

  const [mongoId, setMongoId] = useState(0);
  const [influencerName, setInfluencerName] = useState("");
  const [campaign, setCampaign] = useState("");
  const [status, setStatus] = useState("Active");
  const [error, setError] = useState({
    influencerName: "",
    campaign: "",
  });

  useEffect(() => {
    setMongoId(dialogueData?.data?._id || 0);
    setInfluencerName(dialogueData?.data?.influencerName || "");
    setCampaign(dialogueData?.data?.campaign || "");
    setStatus(dialogueData?.data?.status || "Active");
  }, [dialogueData]);

  const handleSubmit = async () => {
    let formHasError = false;
    let errorObj = { ...error };

    if (!influencerName) {
      errorObj.influencerName = "Influencer Adı Zorunludur!";
      formHasError = true;
    }

    if (!campaign) {
      errorObj.campaign = "Kampanya Adı Zorunludur!";
      formHasError = true;
    }

    if (formHasError) {
      return setError({ ...errorObj });
    }

    try {
      const formData = {
        influencerName,
        campaign,
        status,
      };

      if (mongoId) {
        await dispatch(updateInfluencerPartnership(mongoId, formData));
      } else {
        await dispatch(createInfluencerPartnership(formData));
      }
      dispatch({ type: CLOSE_DIALOGUE });
    } catch (error) {
      console.error("İşlem sırasında hata:", error);
    }
  };

  return (
    <div className="mainDialogue fade-in">
      <div className="Dialogue">
        <div className="dialogueHeader">
          <div className="headerTitle fw-bold">Influencer Ortaklığı</div>
          <div
            className="closeBtn"
            onClick={() => {
              dispatch({ type: CLOSE_DIALOGUE });
            }}
          >
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
        <div className="dialogueMain">
          <div className="row">
            <div className="col-12">
              <Input
                label="Influencer Adı"
                id="influencerName"
                newClass="text-capitalize"
                type="text"
                value={influencerName}
                errorMessage={error.influencerName && error.influencerName}
                onChange={(e) => {
                  setInfluencerName(e.target.value);
                  setError((prevErrors) => ({ ...prevErrors, influencerName: "" }));
                }}
              />
            </div>
            <div className="col-12">
              <Input
                label="Kampanya"
                id="campaign"
                type="text"
                value={campaign}
                errorMessage={error.campaign && error.campaign}
                onChange={(e) => {
                  setCampaign(e.target.value);
                  setError((prevErrors) => ({ ...prevErrors, campaign: "" }));
                }}
              />
            </div>
            <div className="col-12">
              <label className="form-label">Durum</label>
              <select
                className="form-select"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="Active">Aktif</option>
                <option value="Inactive">Pasif</option>
                <option value="Pending">Beklemede</option>
              </select>
            </div>
          </div>
        </div>
        <div className="dialogueFooter">
          <div className="dialogueBtn">
            {!mongoId ? (
              <Button
                btnName="Oluştur"
                btnColor="btnBlackPrime text-white"
                style={{ borderRadius: "5px", width: "80px" }}
                newClass="me-2"
                onClick={handleSubmit}
              />
            ) : (
              <Button
                btnName="Güncelle"
                btnColor="btnBlackPrime text-white"
                style={{ borderRadius: "5px", width: "80px" }}
                newClass="me-2"
                onClick={handleSubmit}
              />
            )}
            <Button
              btnName="İptal"
              btnColor="bg-danger text-white"
              style={{ borderRadius: "5px", width: "80px" }}
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfluencerPartnershipDialog;
