import React, { useEffect, useState } from "react";
import Button from "../../extra/Button";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Input from "../../extra/Input";
import { useDispatch, useSelector } from "react-redux";
import { createPlan, fetchPlanList } from "../../store/plan/plan.action";

const PlanDialog = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();
  const PLAN_NAMES = ['individual', 'business', 'businessPlus'];

  const [formData, setFormData] = useState({
    title: "",
    planName: "individual", // Default değer
    price: {
      monthly: "",
      yearly: ""
    },
    features: [],
    credit: {
      monthlyMinutes: 30,
      additionalCreditOptions: [30, 60, 90, 120, 180],
      allowCarryOver: false
    },
    renewalAlerts: {
      yearly: [21, 7]
    }
  });
  
  const [error, setError] = useState({
    title: "",
    planName: "",
    monthlyPrice: "",
    yearlyPrice: ""
  });

  const [newFeature, setNewFeature] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name === "monthlyPrice") {
      setFormData(prev => ({
        ...prev,
        price: {
          ...prev.price,
          monthly: Number(value) || 0
        }
      }));
    } else if (name === "yearlyPrice") {
      setFormData(prev => ({
        ...prev,
        price: {
          ...prev.price,
          yearly: Number(value) || 0
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
    
    setError(prev => ({
      ...prev,
      [name]: ""
    }));
  };

  const handleAddFeature = () => {
    if (newFeature.trim()) {
      setFormData(prev => ({
        ...prev,
        features: [...prev.features, { description: newFeature, status: true }]
      }));
      setNewFeature("");
    }
  };

  const handleRemoveFeature = (index) => {
    setFormData(prev => ({
      ...prev,
      features: prev.features.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async () => {
    let formHasError = false;
    let errorObj = { ...error };

    if (!formData.title) {
      errorObj.title = "Plan Title is Required!";
      formHasError = true;
    }
    if (!formData.planName) {
      errorObj.planName = "Plan Type is Required!";
      formHasError = true;
    }
    if (!formData.price.monthly) {
      errorObj.monthlyPrice = "Monthly Price is Required!";
      formHasError = true;
    }
    if (!formData.price.yearly) {
      errorObj.yearlyPrice = "Yearly Price is Required!";
      formHasError = true;
    }

    if (formHasError) {
      return setError(errorObj);
    }

    try {
      const planData = {
        title: formData.title,
        planName: formData.planName,
        price: {
          monthly: Number(formData.price.monthly),
          yearly: Number(formData.price.yearly)
        },
        features: formData.features.map(feature => ({
          description: feature.description,
          status: Boolean(feature.status)
        })),
        credit: {
          monthlyMinutes: Number(formData.credit.monthlyMinutes),
          additionalCreditOptions: formData.credit.additionalCreditOptions,
          allowCarryOver: Boolean(formData.credit.allowCarryOver)
        },
        renewalAlerts: {
          yearly: formData.renewalAlerts.yearly
        }
      };

      const result = await dispatch(createPlan(planData));
      
      if (result.success) {
        await dispatch(fetchPlanList());
        dispatch({ type: CLOSE_DIALOGUE });
      }
    } catch (error) {
      console.error("Error during operation:", error);
    }
  };

  return (
    <div className="mainDialogue fade-in">
      <div className="Dialogue">
        <div className="dialogueHeader">
          <div className="headerTitle fw-bold">Create New Plan</div>
          <div className="closeBtn" onClick={() => dispatch({ type: CLOSE_DIALOGUE })}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
        <div className="dialogueMain">
          <div className="row">
            <div className="col-12">
              <Input
                label="Plan Title"
                id="title"
                name="title"
                type="text"
                value={formData.title}
                errorMessage={error.title}
                onChange={handleChange}
              />
            </div>
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="planName">Plan Type</label>
                <select
                  className="form-select"
                  id="planName"
                  name="planName"
                  value={formData.planName}
                  onChange={handleChange}
                >
                  {PLAN_NAMES.map((name) => (
                    <option key={name} value={name}>
                      {name.charAt(0).toUpperCase() + name.slice(1)}
                    </option>
                  ))}
                </select>
                {error.planName && <div className="text-danger">{error.planName}</div>}
              </div>
            </div>
            <div className="col-12">
              <Input
                label="Monthly Price (DKK)"
                id="monthlyPrice"
                name="monthlyPrice"
                type="number"
                value={formData.price.monthly}
                errorMessage={error.monthlyPrice}
                onChange={handleChange}
              />
            </div>
            <div className="col-12">
              <Input
                label="Yearly Price (DKK)"
                id="yearlyPrice"
                name="yearlyPrice"
                type="number"
                value={formData.price.yearly}
                errorMessage={error.yearlyPrice}
                onChange={handleChange}
              />
            </div>
            
            {/* Features Section */}
            <div className="col-12 mt-3">
              <h6>Features</h6>
              <div className="input-group mb-3">
                <Input
                  type="text"
                  value={newFeature}
                  onChange={(e) => setNewFeature(e.target.value)}
                  placeholder="Add new feature"
                  style={{ borderRadius: "5px 0 0 5px" }}
                />
                <button 
                  className="btn btn-primary" 
                  type="button"
                  onClick={handleAddFeature}
                  style={{ 
                    borderRadius: "0 5px 5px 0",
                    height: "38px",
                    marginLeft: "-1px",
                    width: "80px"
                  }}
                >
                  Add
                </button>
              </div>
              <ul className="list-group mt-2">
                {formData.features.map((feature, index) => (
                  <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                    {feature.description}
                    <button 
                      className="btn btn-sm btn-danger"
                      onClick={() => handleRemoveFeature(index)}
                      style={{ 
                        borderRadius: "4px",
                        padding: "4px 8px"
                      }}
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="dialogueFooter">
          <div className="dialogueBtn">
            <Button
              btnName="Submit"
              btnColor="btnBlackPrime text-white"
              style={{ borderRadius: "5px", width: "80px" }}
              newClass="me-2"
              onClick={handleSubmit}
            />
            <Button
              btnName="Close"
              btnColor="bg-danger text-white"
              style={{ borderRadius: "5px", width: "80px" }}
              onClick={() => dispatch({ type: CLOSE_DIALOGUE })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanDialog;