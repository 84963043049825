import React, { useState, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../extra/Table";
import Button from "../../extra/Button";
import Pagination from "../../extra/Pagination";
import { getCredits, deleteCredit } from "../../store/credit/credit.action";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import { warning } from "../../util/Alert";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Searching from "../../extra/Searching";
import CreditDialog from './CreditDialog';

const formatPrice = (price) => {
  if (!price && price !== 0) return "-";
  return new Intl.NumberFormat('da-DK', { 
    style: 'currency',
    currency: 'DKK',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(price);
};

const useCreditsData = () => {
  const dispatch = useDispatch();
  
  const creditState = useSelector((state) => state.credit);
  const credits = creditState?.data || [];
  const loading = creditState?.loading || false;
  const error = creditState?.error || null;
  const total = creditState?.total || 0;
  
  const refreshCredits = useCallback(() => {
    dispatch(getCredits());
  }, [dispatch]);

  React.useEffect(() => {
    refreshCredits();
  }, [refreshCredits]);

  return { 
    credits: Array.isArray(credits) ? credits : [],
    loading,
    error,
    total,
    refreshCredits
  };
};

const ActionButtons = ({ onEdit, onDelete }) => (
  <div className="d-flex gap-2 justify-content-center">
    <Button
      newClass="themeFont boxCenter userBtn fs-5"
      btnIcon="fa-solid fa-edit"
      style={{
        borderRadius: "5px",
        width: "40px",
        backgroundColor: "#fff",
        color: "#160d98",
      }}
      onClick={onEdit}
    />
    <Button
      newClass="themeFont boxCenter userBtn fs-5"
      btnIcon="fa-solid fa-trash"
      style={{
        borderRadius: "5px",
        width: "40px",
        backgroundColor: "#fff",
        color: "#cd2c2c",
      }}
      onClick={onDelete}
    />
  </div>
);

const Credit = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  
  const { credits, loading, error, total, refreshCredits } = useCreditsData();
  const { dialogue } = useSelector((state) => state.dialogue);

  const filteredData = useMemo(() => {
    if (!Array.isArray(credits)) return [];
    if (!search) return credits;

    const searchLower = search.toLowerCase();
    return credits.filter((item) => {
      const title = item?.title?.toLowerCase() || "";
      const description = item?.description?.toLowerCase() || "";
      return title.includes(searchLower) || description.includes(searchLower);
    });
  }, [credits, search]);

  const paginatedData = useMemo(() => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return filteredData.slice(startIndex, endIndex);
  }, [filteredData, page, rowsPerPage]);

  const handleFilterData = useCallback((searchValue) => {
    if (typeof searchValue === "string") {
      setSearch(searchValue.toLowerCase());
      setPage(0);
    }
  }, []);

  const handleDelete = useCallback(async (id) => {
    try {
      const shouldDelete = await warning();
      if (!shouldDelete || loading) return;

      const success = await dispatch(deleteCredit(id));
      if (success) {
        refreshCredits();
        
        const remainingItems = total - 1;
        const lastPage = Math.ceil(remainingItems / rowsPerPage) - 1;
        if (page > lastPage) {
          setPage(Math.max(0, lastPage));
        }
      }
    } catch (error) {
      console.error('Delete failed:', error);
    }
  }, [dispatch, page, rowsPerPage, total, loading, refreshCredits]);

  const handleOpenDialog = useCallback((data = null) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: { 
        type: "credit",
        data: data ? {
          _id: data._id,
          title: data.title,
          description: data.description,
          price: data.price,
          minutes: data.minutes
        } : null
      },
    });
  }, [dispatch]);

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  }, []);

  const columns = useMemo(() => [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => <span>{page * rowsPerPage + index + 1}</span>,
    },
    {
      Header: "Title",
      body: "title",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">{row?.title || "-"}</p>
        </div>
      ),
    },
    {
      Header: "Description",
      body: "description",
      Cell: ({ row }) => (
        <div>
          <p 
            className="mb-0 text-truncate"
            title={row?.description}
            style={{ maxWidth: "400px" }}
          >
            {row?.description || "-"}
          </p>
        </div>
      ),
    },
    {
      Header: "Minutes",
      body: "minutes",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">{row?.minutes || "-"}</p>
        </div>
      ),
    },
    {
      Header: "Price",
      body: "price",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">{formatPrice(row?.price)}</p>
        </div>
      ),
    },
    {
      Header: "Actions",
      body: "",
      Cell: ({ row }) => (
        <ActionButtons 
          onEdit={() => handleOpenDialog(row)} 
          onDelete={() => handleDelete(row._id)}
        />
      ),
    },
  ], [page, rowsPerPage, handleDelete, handleOpenDialog]);

  if (loading) {
    return (
      <div className="p-4">
        <Skeleton count={5} height={40} className="mb-2" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center p-4 text-danger">
        <p className="mb-0">Error: {error}</p>
      </div>
    );
  }

  if (!Array.isArray(credits) || credits.length === 0) {
    return (
      <div className="text-center p-4">
        <p className="mb-0">No credit packages found</p>
      </div>
    );
  }

  return (
    <div className="mainSellerTable">
      <div className="sellerTable">
        <div className="sellerHeader primeHeader">
          <div className="row">
            <div className="col-4">
              <Button
                newClass="whiteFont"
                btnColor="btnBlackPrime"
                btnIcon="fa-solid fa-plus"
                btnName="Add Credit"
                onClick={() => handleOpenDialog()}
                style={{ borderRadius: "10px" }}
              />
              {dialogue && <CreditDialog refreshData={refreshCredits} />}
            </div>
            <div className="col-8">
              <Searching
                type="client"
                data={credits}
                column={columns}
                onFilterData={handleFilterData}
                serverSearching={handleFilterData}
                button={true}
                setSearchValue={setSearch}
                searchValue={search}
              />
            </div>
          </div>
        </div>
  
        <div className="sellerMain">
          <div className="tableMain mt-2 brandTable">
            {paginatedData.length === 0 ? (
              <div className="text-center p-4">Credit package not found</div>
            ) : (
              <>
                <Table
                  data={paginatedData}
                  mapData={columns}
                  PerPage={rowsPerPage}
                  Page={page}
                  type="client"
                />
                <Pagination
                  component="div"
                  count={filteredData.length}
                  serverPage={page}
                  type="client"
                  onPageChange={handleChangePage}
                  serverPerPage={rowsPerPage}
                  totalData={total}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
          </div>
        </div>
        <div className="ProductFooter primeFooter" />
      </div>
    </div>
  );
};

export default Credit;