import * as actionType from "./influencer.type";
import { apiInstanceFetch } from "../../util/api";

export const fetchInfluencerList = () => (dispatch) => {
  apiInstanceFetch.get(`influencer/getAllInfluencers`)
    .then((res) => {
      if (res.status && res.influencers) {
        // Transform the data to match your component's expectations
        const transformedInfluencers = res.influencers.map(influencer => ({
          id: influencer._id,
          name: `${influencer.userId?.firstName} ${influencer.userId?.lastName}`,
          email: influencer.userId?.email,
          isActive: influencer.isActive,
          liveStreamCredits: influencer.liveStreamCredits,
          socialMediaLinks: influencer.socialMediaLinks,
          minimumCommissionRate: influencer.minimumCommissionRate,
          userImage: influencer.userId?.image,
          location: influencer.userId?.location,
          mobileNumber: influencer.userId?.mobileNumber,
          followers: influencer.userId?.followers,
          following: influencer.userId?.following
        }));

        dispatch({
          type: actionType.GET_INFLUENCER_LIST,
          payload: transformedInfluencers,
        });
      }
    })
    .catch(error => {
      console.error('Error fetching influencers:', error);
    });
};