// CreditDialog.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../extra/Button";
import Input from "../../extra/Input";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import { createCredit, updateCredit } from "../../store/credit/credit.action";

const CreditDialog = () => {
  const dispatch = useDispatch();
  const { dialogueData } = useSelector((state) => state.dialogue);

  const [mongoId, setMongoId] = useState(0);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    price: "",
    minutes: ""
  });
  const [error, setError] = useState({
    title: "",
    description: "",
    price: "",
    minutes: ""
  });

  useEffect(() => {
    setMongoId(dialogueData?._id || 0);
    setFormData({
      title: dialogueData?.title || "",
      description: dialogueData?.description || "",
      price: dialogueData?.price ? String(dialogueData.price) : "",
      minutes: dialogueData?.minutes ? String(dialogueData.minutes) : ""
    });
  }, [dialogueData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setError(prev => ({ ...prev, [name]: "" }));
  };

  const handleSubmit = async () => {
    // Basic validation like in BrandDialog
    let formHasError = false;
    let errorObj = { ...error };

    if (!formData.title) {
      errorObj.title = "Title is Required!";
      formHasError = true;
    }
    if (!formData.description) {
      errorObj.description = "Description is Required!";
      formHasError = true;
    }
    if (!formData.price) {
      errorObj.price = "Price is Required!";
      formHasError = true;
    }
    if (!formData.minutes) {
      errorObj.minutes = "Minutes is Required!";
      formHasError = true;
    }

    if (formHasError) {
      return setError(errorObj);
    }

    try {
      const submitData = {
        ...formData,
        price: Number(formData.price),
        minutes: Number(formData.minutes)
      };

      if (mongoId) {
        await dispatch(updateCredit(mongoId, submitData));
      } else {
        await dispatch(createCredit(submitData));
      }
      dispatch({ type: CLOSE_DIALOGUE });
    } catch (error) {
      console.error("Operation failed:", error);
    }
  };

  return (
    <div className="mainDialogue fade-in">
      <div className="Dialogue">
        <div className="dialogueHeader">
          <div className="headerTitle fw-bold">
            {mongoId ? "Edit Credit Package" : "Add Credit Package"}
          </div>
          <div
            className="closeBtn"
            onClick={() => dispatch({ type: CLOSE_DIALOGUE })}
          >
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>

        <div className="dialogueMain">
          <div className="row">
            <div className="col-12">
              <Input
                label="Title"
                name="title"
                type="text"
                value={formData.title}
                errorMessage={error.title}
                onChange={handleChange}
                placeholder="Enter credit package title"
              />
            </div>
            <div className="col-12">
              <Input
                label="Description"
                name="description"
                type="text"
                value={formData.description}
                errorMessage={error.description}
                onChange={handleChange}
                multiline
                rows={3}
                placeholder="Enter credit package description"
              />
            </div>
            <div className="col-12">
              <Input
                label="Price (kr)"
                name="price"
                type="number"
                value={formData.price}
                errorMessage={error.price}
                onChange={handleChange}
                placeholder="Enter price"
              />
            </div>
            <div className="col-12">
              <Input
                label="Minutes"
                name="minutes"
                type="number"
                value={formData.minutes}
                errorMessage={error.minutes}
                onChange={handleChange}
                placeholder="Enter minutes"
              />
            </div>
          </div>
        </div>

        <div className="dialogueFooter">
          <div className="dialogueBtn">
            <Button
              btnName={mongoId ? "Update" : "Submit"}
              btnColor="btnBlackPrime text-white"
              style={{ borderRadius: "5px", width: "80px" }}
              newClass="me-2"
              onClick={handleSubmit}
            />
            <Button
              btnName="Close"
              btnColor="bg-danger text-white"
              style={{ borderRadius: "5px", width: "80px" }}
              onClick={() => dispatch({ type: CLOSE_DIALOGUE })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditDialog;