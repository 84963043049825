import {
  GET_INFLUENCER_PARTNERSHIPS,
  GET_INFLUENCER_PARTNERSHIPS_SUCCESS,
  GET_INFLUENCER_PARTNERSHIPS_FAIL,
  CREATE_INFLUENCER_PARTNERSHIP,
  CREATE_INFLUENCER_PARTNERSHIP_SUCCESS,
  CREATE_INFLUENCER_PARTNERSHIP_FAIL,
} from './influencerPartnership.type';

const initialState = {
  partnerships: [],
  loading: false,
  error: null,
};

export const influencerPartnershipReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INFLUENCER_PARTNERSHIPS:
      return {
        ...state,
        loading: true,
      };
    case GET_INFLUENCER_PARTNERSHIPS_SUCCESS:
      return {
        ...state,
        loading: false,
        partnerships: action.payload,
      };
    case GET_INFLUENCER_PARTNERSHIPS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

