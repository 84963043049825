import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceInformation } from "../../store/invoiceInformation/invoiceInformation.action";
import Table from "../../extra/Table";
import Title from "../../extra/Title";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Searching from "../../extra/Searching";
import Pagination from "../../extra/Pagination";

const InvoiceInformation = () => {
    const dispatch = useDispatch();
    const [search, setSearch] = useState("");
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);

    const invoiceInformation = useSelector((state) => state.invoiceInformation.invoiceInformation);

    const flattenData = (data) => {
        return data.map(item => ({
            ...item,
            firstName: item.userId?.firstName || "",
            lastName: item.userId?.lastName || "",
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(getInvoiceInformation());
            } catch (error) {
                console.error("Error fetching invoice information:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [dispatch]);

    useEffect(() => {
        if (invoiceInformation) {
            setData(flattenData(invoiceInformation));
        }
    }, [invoiceInformation]);

    const handleFilterData = (searchTerm) => {
        if (typeof searchTerm !== "string") {
            setData(searchTerm);
            return;
        }

        setSearch(searchTerm);
        const trimmedSearch = searchTerm.trim();

        if (!trimmedSearch) {
            setData(flattenData(invoiceInformation));
            return;
        }

        const lowercaseSearch = trimmedSearch.toLowerCase();
        const filteredResults = flattenData(invoiceInformation).filter((item) => {
            return (
                item.firstName.toLowerCase().includes(lowercaseSearch) ||
                item.lastName.toLowerCase().includes(lowercaseSearch) ||
                (item.businessName || "").toLowerCase().includes(lowercaseSearch) ||
                (item.cvr || "").toLowerCase().includes(lowercaseSearch)
            );
        });

        setData(filteredResults);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event, 10));
        setPage(0);
    };

    // mapData remains the same...
    const mapData = [
        {
            Header: "No",
            width: "50px",
            Cell: ({ index }) => <span>{index + 1}</span>,
        },
        { 
            Header: "First Name",
            body: "firstName",
            Cell: ({ row }) => (
                <div>
                    <p className="mb-0">
                        {row.userId?.firstName || "-"}
                    </p>
                </div>
            )
        },
        { 
            Header: "Last Name",
            body: "lastName",
            Cell: ({ row }) => (
                <div>
                    <p className="mb-0">
                        {row.userId?.lastName || "-"}
                    </p>
                </div>
            )
        },
        { 
            Header: "Business Name",
            body: "businessName",
            Cell: ({ row }) => (
                <div>
                    <p className="mb-0">{row.businessName || "-"}</p>
                </div>
            )
        },
        { 
            Header: "CVR",
            body: "cvr",
            Cell: ({ row }) => (
                <div>
                    <p className="mb-0">{row.cvr || "-"}</p>
                </div>
            )
        },
        { 
            Header: "Name",
            body: "name",
            Cell: ({ row }) => (
                <div>
                    <p className="mb-0">{row.name || "-"}</p>
                </div>
            )
        },
        { 
            Header: "Address",
            body: "address",
            Cell: ({ row }) => (
                <div>
                    <p className="mb-0">{row.address || "-"}</p>
                </div>
            )
        },
        { 
            Header: "Post Code",
            body: "zipCode",
            Cell: ({ row }) => (
                <div>
                    <p className="mb-0">{row.zipCode || "-"}</p>
                </div>
            )
        },
        { 
            Header: "City",
            body: "city",
            Cell: ({ row }) => (
                <div>
                    <p className="mb-0">{row.city || "-"}</p>
                </div>
            )
        },
        { 
            Header: "State",
            body: "state",
            Cell: ({ row }) => (
                <div>
                    <p className="mb-0">{row.state || "-"}</p>
                </div>
            )
        },
        { 
            Header: "Country",
            body: "country",
            Cell: ({ row }) => (
                <div>
                    <p className="mb-0">{row.country || "-"}</p>
                </div>
            )
        },
        { 
            Header: "Delivery Address",
            body: "isSameAsDeliveryAddress",
            Cell: ({ row }) => (
                <div>
                    <p className="mb-0">
                        {row.isSameAsDeliveryAddress ? "Same" : "Different"}
                    </p>
                </div>
            )
        },
        { 
            Header: "Created Date",
            body: "createdAt",
            Cell: ({ row }) => (
                <div>
                    <p className="mb-0">
                        {row.createdAt ? new Date(row.createdAt).toLocaleDateString() : "-"}
                    </p>
                </div>
            )
        }
    ];

    if (loading) {
        return (
            <div className="mainSellerTable">
                <div className="sellerTable">
                    <div className="sellerHeader primeHeader">
                        <div className="row">
                            <div className="col-12">
                                <Skeleton height={40} />
                            </div>
                        </div>
                    </div>
                    <div className="sellerMain">
                        <div className="tableMain mt-2 brandTable">
                            <Skeleton count={5} height={40} className="mb-2" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="mainSellerTable">
            <div className="sellerTable">
                <div className="sellerHeader primeHeader">
                    <div className="row">
                        <div className="col-12">
                            <Title name="Invoice Information" />
                        </div>
                        <div className="col-12 mt-3">
                            <Searching
                                type="client"
                                data={data}
                                setData={setData}
                                column={mapData}
                                onFilterData={handleFilterData}
                                button={true}
                                serverSearching={true}
                                setSearchValue={setSearch}
                                searchValue={search}
                            />
                        </div>
                    </div>
                </div>

                <div className="sellerMain">
                    <div className="tableMain mt-2">
                        {!Array.isArray(data) || data.length === 0 ? (
                            <div className="text-center p-4">Invoice information not found</div>
                        ) : (
                            <>
                                <Table
                                    data={data}
                                    mapData={mapData}
                                    PerPage={rowsPerPage}
                                    Page={page}
                                    type="client"
                                />
                                <div className="d-flex justify-content-end mt-3">
                                    <Pagination
                                        count={Math.ceil(data.length / rowsPerPage)}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={(value) => handleChangeRowsPerPage(value)}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvoiceInformation;