import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "../src/assets/css/style.css";
import "../src/assets/css/default.css";
import "../src/assets/css/custom.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Component/store/Provider";
import { baseURL, key } from "./Component/util/config";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import Loader from "./Component/util/Loader";
import {
    CLOSE_LOADER,
    LOADER_OPEN,
} from "./Component/store/dialogue/dialogue.type";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            hasError: false, 
            error: null, 
            errorInfo: null,
            key: 0 
        };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
        console.error('Error caught by boundary:', error, errorInfo);
    }

    resetError = () => {
        this.setState({
            hasError: false,
            error: null,
            errorInfo: null,
            key: this.state.key + 1
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="error-boundary-container" style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 9999
                }}>
                    <div style={{
                        padding: '2rem',
                        borderRadius: '8px',
                        backgroundColor: 'white',
                        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                        textAlign: 'center'
                    }}>
                        <h2 style={{ marginBottom: '1rem' }}>Bir Hata Oluştu</h2>
                        <p style={{ marginBottom: '1.5rem' }}>
                            Uygulama beklenmeyen bir hatayla karşılaştı.
                        </p>
                        <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                            <button
                                onClick={this.resetError}
                                style={{
                                    padding: '0.5rem 1rem',
                                    backgroundColor: '#4CAF50',
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '4px',
                                    cursor: 'pointer'
                                }}
                            >
                                Tekrar Dene
                            </button>
                            <button
                                onClick={() => window.location.reload()}
                                style={{
                                    padding: '0.5rem 1rem',
                                    backgroundColor: '#2196F3',
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '4px',
                                    cursor: 'pointer'
                                }}
                            >
                                Sayfayı Yenile
                            </button>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment key={this.state.key}>
                {this.props.children}
            </React.Fragment>
        );
    }
}

axios.defaults.baseURL = baseURL;
axios.defaults.headers.common["key"] = key;

axios.interceptors.request.use(
    (req) => {
        store.dispatch({ type: LOADER_OPEN });
        return req;
    },
    (error) => {
        store.dispatch({ type: CLOSE_LOADER });
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (res) => {
        store.dispatch({ type: CLOSE_LOADER });
        return res;
    },
    (error) => {
        store.dispatch({ type: CLOSE_LOADER });
        return Promise.reject(error);
    }
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ErrorBoundary>
        <BrowserRouter>
            <Provider store={store}>
                <App />
                <ToastContainer />
                <Loader />
            </Provider>
        </BrowserRouter>
    </ErrorBoundary>
);

reportWebVitals();