import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updatePlan, fetchPlanList } from "../../store/plan/plan.action";
import Input from "../../extra/Input";
import Button from "../../extra/Button";
import { setToast } from "../../util/toast";

const PLAN_NAMES = ['individual', 'business', 'businessPlus'];

const PlanEdit = ({ show, onHide, planData }) => {
  const dispatch = useDispatch();
  const [newFeature, setNewFeature] = useState("");

  const [formData, setFormData] = useState({
    title: "",
    price: {
      monthly: "",
      yearly: ""
    },
    features: [],
    credit: {
      monthlyMinutes: 30,
      additionalCreditOptions: [30, 60, 90, 120, 180],
      allowCarryOver: false
    },
    renewalAlerts: {
      yearly: [21, 7]
    }
  });

  const [error, setError] = useState({
    title: "",
    monthlyPrice: "",
    yearlyPrice: ""
  });

  useEffect(() => {
    console.log("Gelen Plan Data:", planData);
    console.log("Gelen Plan Title:", planData?.title);

    if (planData) {
      const updatedFormData = {
        ...planData,
        title: planData.title,
        price: {
          monthly: planData.price?.monthly || '',
          yearly: planData.price?.yearly || ''
        },
        features: planData.features || [],
        credit: {
          monthlyMinutes: planData.credit?.monthlyMinutes || 30,
          additionalCreditOptions: planData.credit?.additionalCreditOptions || [30, 60, 90, 120, 180],
          allowCarryOver: planData.credit?.allowCarryOver || false
        },
        renewalAlerts: {
          yearly: planData.renewalAlerts?.yearly || [21, 7]
        }
      };

      console.log("Güncellenecek Form Data:", updatedFormData);
      setFormData(updatedFormData);
    }
  }, [planData]);

  useEffect(() => {
    if (show) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }

    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [show]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name === "monthlyPrice") {
      setFormData(prev => ({
        ...prev,
        price: {
          ...prev.price,
          monthly: value
        }
      }));
    } else if (name === "yearlyPrice") {
      setFormData(prev => ({
        ...prev,
        price: {
          ...prev.price,
          yearly: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
    
    setError(prev => ({
      ...prev,
      [name]: ""
    }));
  };

  const handleFeatureChange = (index) => {
    setFormData(prev => ({
      ...prev,
      features: prev.features.map((feature, idx) => 
        idx === index ? { ...feature, status: !feature.status } : feature
      )
    }));
  };

  const handleCreditChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    setFormData(prev => ({
      ...prev,
      credit: {
        ...prev.credit,
        [name]: type === 'checkbox' ? checked : Number(value)
      }
    }));
  };

  const handleSubmit = async () => {
    let formHasError = false;
    let errorObj = { ...error };

    if (!formData.title) {
      errorObj.title = "Plan Name is required!";
      formHasError = true;
    }
    if (!formData.price.monthly) {
      errorObj.monthlyPrice = "Monthly Price is required!";
      formHasError = true;
    }
    if (!formData.price.yearly) {
      errorObj.yearlyPrice = "Yearly Price is required!";
      formHasError = true;
    }

    if (formHasError) {
      setError(errorObj);
      return;
    }

    try {
      const updateData = {
        title: formData.title,
        planName: formData.planName,
        price: {
          monthly: Number(formData.price.monthly),
          yearly: Number(formData.price.yearly)
        },
        features: formData.features.map(feature => ({
          description: feature.description,
          status: Boolean(feature.status)
        })),
        credit: {
          monthlyMinutes: Number(formData.credit.monthlyMinutes) || 30,
          additionalCreditOptions: formData.credit.additionalCreditOptions.map(Number),
          allowCarryOver: Boolean(formData.credit.allowCarryOver)
        },
        renewalAlerts: {
          yearly: formData.renewalAlerts.yearly.map(Number)
        }
      };

      console.log('Gönderilecek veri:', updateData);

      const result = await dispatch(updatePlan({
        id: planData._id,
        data: updateData
      }));
      
      if (result.success) {
        await dispatch(fetchPlanList());
        onHide();
      }
    } catch (error) {
      console.error("Güncelleme sırasında hata:", error);
    }
  };

  const handleAddFeature = () => {
    if (!newFeature.trim()) {
      setToast("Feature description cannot be empty", "error");
      return;
    }

    setFormData(prev => ({
      ...prev,
      features: [
        ...prev.features,
        {
          description: newFeature.trim(),
          status: true
        }
      ]
    }));
    setNewFeature("");
  };

  const handleRemoveFeature = (index) => {
    setFormData(prev => ({
      ...prev,
      features: prev.features.filter((_, idx) => idx !== index)
    }));
  };

  return (
    <>
      <div className={`modal fade ${show ? 'show' : ''}`} 
           style={{ display: show ? 'block' : 'none', zIndex: 1055 }}
           tabIndex="-1"
           role="dialog"
           aria-hidden={!show}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Plan Edit</h5>
              <button type="button" className="btn-close" onClick={onHide} aria-label="Close"></button>
            </div>
            
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <Input
                    label="Plan Title"
                    id="title"
                    name="title"
                    type="text"
                    value={formData.title}
                    errorMessage={error.title}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="planName">Plan Type</label>
                    <select
                      className="form-select"
                      id="planName"
                      name="planName"
                      value={formData.planName}
                      onChange={handleChange}
                    >
                      {PLAN_NAMES.map((name) => (
                        <option key={name} value={name}>
                          {name.charAt(0).toUpperCase() + name.slice(1)}
                        </option>
                      ))}
                    </select>
                    {error.planName && <div className="text-danger">{error.planName}</div>}
                  </div>
                </div>
                <div className="col-12">
                  <Input
                    label="Monthly Price (DKK)"
                    id="monthlyPrice"
                    name="monthlyPrice"
                    type="number"
                    value={formData.price.monthly}
                    errorMessage={error.monthlyPrice}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <Input
                    label="Yearly Price (DKK)"
                    id="yearlyPrice"
                    name="yearlyPrice"
                    type="number"
                    value={formData.price.yearly}
                    errorMessage={error.yearlyPrice}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-12 mt-4">
                  <h6 className="mb-3">Credit Settings</h6>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <Input
                        label="Monthly Minutes"
                        id="monthlyMinutes"
                        name="monthlyMinutes"
                        type="number"
                        value={formData.credit.monthlyMinutes}
                        onChange={handleCreditChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="form-check mt-4">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="allowCarryOver"
                          name="allowCarryOver"
                          checked={formData.credit.allowCarryOver}
                          onChange={handleCreditChange}
                        />
                        <label className="form-check-label" htmlFor="allowCarryOver">
                          Allow Carry Over
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 mt-4">
                  <h6 className="mb-3">Features</h6>
                  <div className="features-list">
                    {formData.features.map((feature, index) => (
                      <div key={index} className="form-check mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`feature-${index}`}
                          checked={feature.status}
                          onChange={() => handleFeatureChange(index)}
                        />
                        <label className="form-check-label" htmlFor={`feature-${index}`}>
                          {feature.description}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <Button
                btnName="Update"
                btnColor="btnBlackPrime text-white"
                style={{ borderRadius: "5px", width: "100px" }}
                newClass="me-2"
                onClick={handleSubmit}
              />
              <Button
                btnName="Cancel"
                btnColor="bg-danger text-white"
                style={{ borderRadius: "5px", width: "100px" }}
                onClick={onHide}
              />
            </div>
          </div>
        </div>
      </div>
      {show && <div className="modal-backdrop fade show" style={{ zIndex: 1054 }} />}
    </>
  );
};

export default PlanEdit; 