import {
  GET_CREDIT_INVOICES,
  GET_CREDIT_INVOICES_SUCCESS,
  GET_CREDIT_INVOICES_FAIL,
  CREATE_CREDIT_INVOICE,
  CREATE_CREDIT_INVOICE_SUCCESS,
  CREATE_CREDIT_INVOICE_FAIL,
  UPDATE_CREDIT_INVOICE,
  UPDATE_CREDIT_INVOICE_SUCCESS,
  UPDATE_CREDIT_INVOICE_FAIL,
  DELETE_CREDIT_INVOICE,
  DELETE_CREDIT_INVOICE_SUCCESS,
  DELETE_CREDIT_INVOICE_FAIL
} from './creditInvoice.type';

const initialState = {
  invoices: [],
  loading: false,
  error: null,
};

export const creditInvoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CREDIT_INVOICES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_CREDIT_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        invoices: action.payload,
      };
    case GET_CREDIT_INVOICES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    
    case CREATE_CREDIT_INVOICE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_CREDIT_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_CREDIT_INVOICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_CREDIT_INVOICE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_CREDIT_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_CREDIT_INVOICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_CREDIT_INVOICE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_CREDIT_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        invoices: state.invoices.filter((invoice) => invoice._id !== action.payload),
      };
    case DELETE_CREDIT_INVOICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

