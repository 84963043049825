import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../extra/Table";
import Button from "../../extra/Button";
import Pagination from "../../extra/Pagination";
import { fetchBrands, deleteBrand } from "../../store/brand/brand.action";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import { warning } from "../../util/Alert";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import BrandDialog from "./BrandDialog";
import Searching from "../../extra/Searching";

const Brand = () => {
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  
  const brands = useSelector((state) => state.brand.brands);
  const { dialogue, dialogueData } = useSelector((state) => state.dialogue);

  useEffect(() => {
    dispatch(fetchBrands());
  }, [dispatch]);

  useEffect(() => {
    setData(brands);
  }, [brands]);

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleDelete = async (id) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          dispatch(deleteBrand(id));
        }
      })
      .catch((err) => console.log(err));
  };

  const mapData = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => <span>{index + 1}</span>,
    },
    {
      Header: "Brand Name",
      body: "brandName",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0 text-capitalize">{row.brandName || "-"}</p>
        </div>
      ),
    },
    {
      Header: "Edit",
      body: "",
      Cell: ({ row }) => (
        <Button
          newClass="themeFont boxCenter userBtn fs-5"
          btnIcon="fa-solid fa-edit"
          style={{
            borderRadius: "5px",
            margin: "auto",
            width: "40px",
            backgroundColor: "#fff",
            color: "#160d98",
          }}
          onClick={() => {
            dispatch({
              type: OPEN_DIALOGUE,
              payload: { data: row, type: "brand" },
            });
          }}
        />
      ),
    },
    {
      Header: "Delete",
      body: "",
      Cell: ({ row }) => (
        <button
          className="themeBtn text-center"
          style={{
            borderRadius: "5px",
            margin: "auto",
            width: "40px",
            backgroundColor: "#fff",
            color: "#cd2c2c",
          }}
          onClick={() => handleDelete(row._id)}
        >
          <svg
            width="25"
            height="25"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.04017 6L4.9258 16.6912C4.98927 17.4622 5.646 18.0667 6.41993 18.0667H14.5801C15.354 18.0667 16.0108 17.4622 16.0742 16.6912L16.9598 6H4.04017ZM7.99953 16.0667C7.7378 16.0667 7.5176 15.8631 7.501 15.5979L7.001 7.53123C6.9839 7.25537 7.19337 7.01807 7.46877 7.00097C7.7544 6.98093 7.98147 7.19287 7.99903 7.46873L8.49903 15.5354C8.51673 15.8211 8.2907 16.0667 7.99953 16.0667ZM11 15.5667C11 15.843 10.7764 16.0667 10.5 16.0667C10.2236 16.0667 10 15.843 10 15.5667V7.5C10 7.22363 10.2236 7 10.5 7C10.7764 7 11 7.22363 11 7.5V15.5667ZM13.999 7.53127L13.499 15.5979C13.4826 15.8604 13.2638 16.0791 12.9687 16.0657C12.6933 16.0486 12.4839 15.8113 12.501 15.5354L13.001 7.46877C13.0181 7.1929 13.2598 6.9922 13.5312 7.001C13.8066 7.0181 14.0161 7.2554 13.999 7.53127ZM17 3H14V2.5C14 1.67287 13.3271 1 12.5 1H8.5C7.67287 1 7 1.67287 7 2.5V3H4C3.4477 3 3 3.4477 3 4C3 4.55223 3.4477 5 4 5H17C17.5523 5 18 4.55223 18 4C18 3.4477 17.5523 3 17 3ZM13 3H8V2.5C8 2.22413 8.22413 2 8.5 2H12.5C12.7759 2 13 2.22413 13 2.5V3Z"
              fill="#CD2C2C"
            />
          </svg>
        </button>
      ),
    },
  ];

  if (!Array.isArray(brands)) {
    return (
      <div className="p-4">
        <Skeleton count={5} height={40} className="mb-2" />
      </div>
    );
  }

  return (
    <div className="mainSellerTable">
      <div className="sellerTable">
        <div className="sellerHeader primeHeader">
          <div className="row">
            <div className="col-4">
              <Button
                newClass="whiteFont"
                btnColor="btnBlackPrime"
                btnIcon="fa-solid fa-plus"
                btnName="Add Brand"
                onClick={() => {
                  dispatch({
                    type: OPEN_DIALOGUE,
                    payload: { type: "brand" },
                  });
                }}
                style={{ borderRadius: "10px" }}
              />
              {dialogue && <BrandDialog />}
            </div>
            <div className="col-8">
              <Searching
                type="client"
                data={brands}
                setData={setData}
                column={data}
                onFilterData={handleFilterData}
                serverSearching={handleFilterData}
                button={true}
                setSearchValue={setSearch}
                searchValue={search}
              />
            </div>
          </div>
        </div>

        <div className="sellerMain">
          <div className="tableMain mt-2 brandTable">
            {data.length === 0 ? ( 
              <div className="text-center p-4">Brand not found</div>
            ) : (
              <>
                <Table
                  data={data} 
                  mapData={mapData}
                  PerPage={rowsPerPage}
                  Page={page}
                  type="client"
                />
                <Pagination
                  component="div"
                  count={data.length}
                  serverPage={page}
                  type="client"
                  onPageChange={handleChangePage}
                  serverPerPage={rowsPerPage}
                  totalData={data.length} 
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
          </div>
        </div>
        <div className="ProductFooter primeFooter"></div>

      </div>
    </div>
  );
};

export default Brand;