import * as ActionType from './story.type';

const initialState = {
    story: [],
    loading: false,
    error: null
};

export const storyReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionType.GET_STORY:
            console.log("Reducer'a gelen veri:", action.payload);
            return { 
                ...state, 
                story: action.payload,
                loading: false 
            };
        case ActionType.CREATE_STORY:
            return { 
                ...state, 
                story: [...state.story, action.payload] 
            };
        case ActionType.UPDATE_STORY:
            return {
                ...state,
                story: state.story.map(story => 
                    story._id === action.payload._id ? action.payload : story
                )
            };
        case ActionType.DELETE_STORY:
            return {
                ...state,
                story: state.story.filter(story => story._id !== action.payload)
            };
        default:
            return state;
    }
};