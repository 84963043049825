import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../extra/Table";
import Button from "../../extra/Button";
import Pagination from "../../extra/Pagination";
import { fetchPlanList, deletePlan } from "../../store/plan/plan.action";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import { warning } from "../../util/Alert";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import PlanDialog from "./PlanDialog";
import Searching from "../../extra/Searching";
import { useNavigate } from "react-router-dom";
import PlanEdit from "./PlanEdit";

const Plan = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const plans = useSelector((state) => state.plan.plans);
  const { dialogue, dialogueData } = useSelector((state) => state.dialogue);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchPlanList());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (plans) {
      setData(plans);
    }
  }, [plans]);

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };
  const toggleRowExpand = (rowId) => {
    setExpandedRows(prev => ({
      ...prev,
      [rowId]: !prev[rowId]
    }));
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };
  const handleDelete = async (id) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          dispatch(deletePlan(id));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleEdit = (rowData) => {
    setSelectedPlan(rowData);
    setShowEditModal(true);
  };

  const mapData = [
    {
      Header: "",
      width: "30px",
      Cell: ({ row }) => (
        <button
          onClick={() => toggleRowExpand(row._id)}
          className="btn btn-link p-0 text-primary"
        >
          <i className={`fas fa-chevron-${expandedRows[row._id] ? 'up' : 'down'}`}></i>
        </button>
      ),
    },
    
    {
      Header: "No",
      width: "50px",
      Cell: ({ index }) => <span>{index + 1}</span>,
    },
    {
      Header: "Plan Name",
      body: "title",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0 text-capitalize">{row.title || "-"}</p>
        </div>
      ),
    },
    {
      Header: "Monthly Price",
      body: "price.monthly",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">{row.price?.monthly || "-"} DKK</p>
        </div>
      ),
    },
    {
      Header: "Yearly Price",
      body: "price.yearly",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">{row.price?.yearly || "-"} DKK</p>
        </div>
      ),
    },
    {
      Header: "Features",
      body: "features",
      Cell: ({ row }) => {
        const activeFeatures = row.features?.filter(f => f.status)?.length || 0;
        const totalFeatures = row.features?.length || 0;
        return (
          <div>
            <p className="mb-0">{activeFeatures}/{totalFeatures}</p>
            {expandedRows[row._id] && (
              <div className="mt-2 features-list">
                {row.features?.map((feature, idx) => (
                  <div key={feature._id || idx} className="feature-item d-flex align-items-center mb-1">
                    <i className={`fas fa-${feature.status ? 'check text-success' : 'times text-danger'} me-2`}></i>
                    <span className="feature-text">{feature.description}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        )
      },
    },
    {
      Header: "Monthly Credits",
      body: "credit",
      Cell: ({ row }) => (
        <div>
          <p className="mb-0">{row.credit?.monthlyMinutes || 0} min</p>
          {expandedRows[row._id] && (
            <div className="mt-2">
              <small>Additional Options:</small>
              <div className="credit-options">
                {row.credit?.additionalCreditOptions?.map((option, idx) => (
                  <span key={idx} className="badge bg-light text-dark me-1">{option} min</span>
                ))}
              </div>
            </div>
          )}
        </div>
      ),
    },
    {
      Header: "Actions",
      body: "",
      Cell: ({ row }) => (
        <div className="d-flex gap-2 justify-content-center">
          <Button
            newClass="themeFont boxCenter userBtn fs-5"
            btnIcon="fa-solid fa-edit"
            style={{
              borderRadius: "5px",
              width: "40px",
              backgroundColor: "#fff",
              color: "#160d98",
            }}
            onClick={() => handleEdit(row)}
          />
          <button
            className="themeBtn text-center"
            style={{
              borderRadius: "5px",
              width: "40px",
              backgroundColor: "#fff",
              color: "#cd2c2c",
            }}
            onClick={() => handleDelete(row._id)}
          >
            <i className="fas fa-trash"></i>
          </button>
        </div>
      ),
    },
  ];

  if (loading) {
    return (
      <div className="mainSellerTable">
        <div className="sellerTable">
          <div className="sellerHeader primeHeader">
            <div className="row">
              <div className="col-12">
                <Skeleton height={40} />
              </div>
            </div>
          </div>
          <div className="sellerMain">
            <div className="tableMain mt-2 brandTable">
              <Skeleton count={5} height={40} className="mb-2" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mainSellerTable">
      <div className="sellerTable">
        <div className="sellerHeader primeHeader">
          <div className="row">
            <div className="col-4">
              <Button
                newClass="whiteFont"
                btnColor="btnBlackPrime"
                btnIcon="fa-solid fa-plus"
                btnName="Add Plan"
                onClick={() => {
                  dispatch({
                    type: OPEN_DIALOGUE,
                    payload: { type: "plan" },
                  });
                }}
                style={{ borderRadius: "10px" }}
              />
              {dialogue && <PlanDialog />}
            </div>
            <div className="col-8">
              <Searching
                type="client"
                data={plans}
                setData={setData}
                column={data}
                button={true}
                setSearchValue={setSearch}
                searchValue={search}
              />
            </div>
          </div>
        </div>

        <div className="sellerMain">
          <div className="tableMain mt-2 planTable">
            {!Array.isArray(data) || data.length === 0 ? (
              <div className="text-center p-4">Plan not found</div>
            ) : (
              <>
                <Table
                  data={data}
                  mapData={mapData}
                  PerPage={rowsPerPage}
                  Page={page}
                  type="client"
                />
                <div className="d-flex justify-content-end mt-3">
                  <Pagination
                    component="div"
                    count={data.length}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      
      <PlanEdit 
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        planData={selectedPlan}
      />
    </div>
  );
};

export default Plan;