import { apiInstanceFetch } from '../../util/api';
import {
  GET_ORDER_INVOICE_REQUESTS,
  GET_ORDER_INVOICE_REQUESTS_SUCCESS,
  GET_ORDER_INVOICE_REQUESTS_FAIL,
} from './orderInvoiceRequest.type';

export const getOrderInvoiceRequests = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ORDER_INVOICE_REQUESTS });
    
    const response = await apiInstanceFetch.get('orderInvoiceRequest/getAllInvoiceRequests');
    
    if (!response?.data) {
      throw new Error('Server response is empty');
    }

    const invoiceRequests = Array.isArray(response.data) ? response.data : [];
    
    dispatch({
      type: GET_ORDER_INVOICE_REQUESTS_SUCCESS,
      payload: invoiceRequests
    });

  } catch (error) {
    dispatch({
      type: GET_ORDER_INVOICE_REQUESTS_FAIL,
      payload: error?.response?.data?.message || error?.message || 'Fatura talepleri alınırken bir hata oluştu',
    });
  }
};