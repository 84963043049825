export const GET_INFLUENCER_PARTNERSHIPS = 'GET_INFLUENCER_PARTNERSHIPS';
export const GET_INFLUENCER_PARTNERSHIPS_SUCCESS = 'GET_INFLUENCER_PARTNERSHIPS_SUCCESS';
export const GET_INFLUENCER_PARTNERSHIPS_FAIL = 'GET_INFLUENCER_PARTNERSHIPS_FAIL';

export const CREATE_INFLUENCER_PARTNERSHIP = 'CREATE_INFLUENCER_PARTNERSHIP';
export const CREATE_INFLUENCER_PARTNERSHIP_SUCCESS = 'CREATE_INFLUENCER_PARTNERSHIP_SUCCESS';
export const CREATE_INFLUENCER_PARTNERSHIP_FAIL = 'CREATE_INFLUENCER_PARTNERSHIP_FAIL';

export const UPDATE_INFLUENCER_PARTNERSHIP = 'UPDATE_INFLUENCER_PARTNERSHIP';
export const UPDATE_INFLUENCER_PARTNERSHIP_SUCCESS = 'UPDATE_INFLUENCER_PARTNERSHIP_SUCCESS';
export const UPDATE_INFLUENCER_PARTNERSHIP_FAIL = 'UPDATE_INFLUENCER_PARTNERSHIP_FAIL';

export const DELETE_INFLUENCER_PARTNERSHIP = 'DELETE_INFLUENCER_PARTNERSHIP';
export const DELETE_INFLUENCER_PARTNERSHIP_SUCCESS = 'DELETE_INFLUENCER_PARTNERSHIP_SUCCESS';
export const DELETE_INFLUENCER_PARTNERSHIP_FAIL = 'DELETE_INFLUENCER_PARTNERSHIP_FAIL';
